import "../../../vendor/masmerise/livewire-toaster/resources/js"; // 👈
var basket_pay, billing_confirm, files;

/* ------------------------------------------------------------------------------------------------------------------------

	Begin main functions for abandon based file upload Jquery / Ajax

	------------------------------------------------------------------------------------------------------------------------ */

// Grab the files and set them to our variable
function prepareUpload(event) {
  files = event.target.files;
  var form = $(this).closest("form");
  // Remove prop (globally)
  $("button", form).prop("disabled", false);
  $(".form-messages", form).html("");
  // Check if the file is larger than 130kb.
  if (form.hasClass("file-uploader")) {
    //If using the file uploader, file size limit is 50mb
    if (files[0].size > 52428800) {
      // Make sure the upload button is disabled.
      $("button", form).prop("disabled", "disabled");
      // Style for error messages div.
      $(".form-messages", form)
        .css({ flex: "0 0 40%", "margin-left": "10px" })
        .html(
          '<div class="ui red center aligned label"><i class="warning sign icon"></i>File size is too large, 50mb limit</div>'
        )
        .fadeIn();
    }
    //if not the file uploader should only be a favicon/banner/social/etc image uploader which has a much stricter file size and only pngs
  } else if (form.hasClass("file-uploader-gpx")) {
    //If using the file uploader, file size limit is 50mb
    if (!files[0].name.endsWith(".gpx")) {
      // Make sure the upload button is disabled.
      $("button", form).prop("disabled", "disabled");
      // Style for error messages div.
      $(".form-messages", form)
        .css({ flex: "0 0 40%", "margin-left": "10px" })
        .html(
          '<div class="ui red center aligned label"><i class="warning sign icon"></i>File must be of type .GPX</div>'
        )
        .fadeIn();
    }
    //if not the file uploader should only be a favicon/banner/social/etc image uploader which has a much stricter file size and only pngs
  } else if (
    $.inArray(files[0].type, [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ]) !== -1
  ) {
    if (files[0].size > 5000000) {
      // Make sure the upload button is disabled.
      $("button", form).prop("disabled", "disabled");
      // Style for error messages div.
      $(".form-messages", form)
        .css({ flex: "0 0 40%", "margin-left": "10px" })
        .html(
          '<div class="ui red center aligned label"><i class="warning sign icon"></i>File size is too large</div>'
        )
        .fadeIn();
    }
  } else {
    //unsupported file type
    // Make sure the upload button is disabled.
    $("button", form).prop("disabled", "disabled");
    // Style for error messages div.
    $(".form-messages", form)
      .css({ flex: "0 0 40%", "margin-left": "10px" })
      .html(
        '<div class="ui red center aligned label"><i class="warning sign icon"></i>Unsupported file type</div>'
      )
      .fadeIn();
  }
}

// Catch the form submit and upload the files
function uploadFiles(event) {
  event.stopPropagation(); // Stop stuff happening
  event.preventDefault(); // Totally stop stuff happening

  // Create a formdata object and add the files
  var data = new FormData(event.target);
  files = event.target.files;
  $.each(files, function (key, value) {
    data.append(key, value);
  });

  data.append("type", $(this).attr("data-type"));
  data.append("folder", $(this).attr("folder"));
  data.append("displayedOn", $(this).attr("data-displayedon"));
  data.append("_token", csrftoken);
  var $action = $(".uploadForm").attr("action");

  $.ajax({
    url: $action,
    type: "POST",
    data: data,
    cache: false,
    dataType: "json",
    processData: false, // Don't process the files
    contentType: false, // Set content type to false as jQuery will tell the server its a query string request
    success: $.proxy(function (data, textStatus, jqXHR) {
      // Success so call function to process the form
      // submitForm(event, data);
      var imgbox = $(this).find("[name='image-output']");
      var url = data.img;
      var img_id = data.img_id;
      url = url.replace(/[\\]/g, "");
      imgbox.html(
        " <img src=" +
          url +
          " class='ui bordered rounded image' /><!--<i style='padding:0px; color:#368a55; position: absolute;top: 15px;left: 96.5%;' class='checkmark icon'></i>-->"
      );
      $("button.fi-upload").removeClass("loading");
      $(this).find(".upload-elems").hide();
      $(this)
        .find(".form-messages")
        .html(
          '<div class="ui green center aligned label"><i class="checkmark icon"></i> Image upload complete</div>'
        )
        .fadeIn();
      //get delete button for uploaded image working
      var remove_image_el = $(this).siblings(".remove-image");
      remove_image_el
        .find(".remove-image-form")
        .attr("action", function (i, v) {
          return v.replace(/xxx/, img_id);
        });
      remove_image_el.show().fadeIn();
      $(document).trigger("image_uploaded");
      setTimeout(function () {
        imgbox.removeClass("flashHiddenImg");
      }, 0.5);
    }, this),
    error: $.proxy(function (data, textStatus, jqXHR) {
      // Handle errors here
      var imgbox = $(this[1]).children("[name='image-output']");
      var json = JSON.parse(data.responseText);
      imgbox.html(
        "<p style='text-align: center;'><i style='color:#f04124;' class='fi-x'></i></br>" +
          json.code +
          " error </br>" +
          json.error +
          "</p>"
      );
      setTimeout(function () {
        imgbox.removeClass("flashHiddenImg");
      }, 0.5);
      $(this).find(".upload-elems").hide();
      $("input:file").val("");
      $(this)
        .find(".form-messages")
        .html(
          '<div class="ui red center aligned label"><i class="warning sign icon"></i> ' +
            json.error +
            "</div>"
        )
        .fadeIn();
      console.log(data.responseText);
    }, this),
  });
}

$(function () {
  // Moved the above function to 'event_list.blade.php' so it's called with Semantic UI 'dropdown' module.

  $("#org_accept").click(function () {
    $("#org_name").show();
    $("#org_name")
      .find("input")
      .each(function () {
        $(this).prop("disabled", false);
      });
  });

  $("#org_decline").click(function () {
    $("#org_name").hide();
    $("#org_name")
      .find("input")
      .each(function () {
        $(this).prop("disabled", true);
      });
  });
  //Search results from AJAX list onclick event
  //currently this is only used by the charity search and is specific to that
  $(document).on(
    "click",
    ".search_form div.rnJq-pick-results td, .search_form_instant div.rnJq-pick-results td",
    function (e) {
      e.preventDefault();
      var top = $(this).parents().find(".search_form");
      if (typeof top[0] == "undefined") {
        top = $(this).parents().find(".search_form_instant");
      }
      var p = $(this).parent();
      //Get first cell of row
      var title_element = p.children(":first");
      var id = p.data("id");
      top.find(".pick-results-value").val(id);
      top.find("input[name=search]").val(title_element.text());

      //If sibling is fundraising, preset the fundraiser too
      if (title_element.next('[name="fundraising_provider"]')) {
        $("#fundraising_provider").val(
          title_element.next('[name="fundraising_provider"]').text()
        );
        if ($("#fundraising_provider").val() != "SG") {
          //below line will need expanding if we increase number of fundraisers
          $("#fundraising_provider").val("Other");
          $("#fundraising_container").show();
        } else {
          $("#fundraising_provider").val("SportsGiving");
          $("#fundraising_container").hide();
        }
      }

      //remove all results
      top.find("div.rnJq-pick-results").html("");

      return;
    }
  );

  //inline AJAX Search//inline AJAX Search
  $(".search_form_instant input, .team_search_form_instant input").on(
    "input keypress",
    function (e) {
      var q = $(this).val();
      var parent = $(this).closest(
        ".search_form_instant, .team_search_form_instant"
      );
      var uri = parent.data("action");
      var uri = uri + q;
      var show = parseInt(parent.data("show"));
      if (show === parseInt(parent.data("show"), 10) || !isNaN(show)) {
      } else {
        show = 3;
      }
      if (q.length > 2) {
        $.getJSON(uri, function (data) {
          var items = [];
          $.each(data, function (key, val) {
            var this_id = val.id;
            var main_div = '<tr data-id="' + val.id + '">';
            var addi =
              '<tr data-id="' +
              val.id +
              '" class="rnJq-hidden" style="display:none;"><td>';
            var count = 1;

            for (var column_heading in val) {
              var value_to_use = val[column_heading];
              if (column_heading != "id" && count > show) {
                //put into output but hidden mname attribute to help find
                //would be maybe do better with a meta cell and all values in a data attribute?
                addi +=
                  "<span name='" +
                  column_heading +
                  "' id='" +
                  column_heading +
                  "-" +
                  this_id +
                  "'>" +
                  value_to_use +
                  "</span>";
              } else if (column_heading != "id" && count <= show) {
                main_div +=
                  "<td name='" + column_heading + "'>" + value_to_use + "</td>";
                count++;
              }
            }

            addi += "</td></tr>";
            main_div += "</tr>";
            items.push(main_div);
            items.push(addi);
            items.push("<tr></tr>");
          });
          $(this).on("focus click", function () {
            $(".rnJq-c-search-results, .rnJq-pick-results").show();
          });
          var res = parent.children(".rnJq-pick-results").show();
          if (res.length < 1) {
            var res = parent.children(".rnJq-c-search-results").show();
          }
          res.empty();
          $("<table />", {
            class: "ui simple striped table autocomplete-results",
            html: items.join(),
          }).appendTo(res);
          $(document).trigger("searchCompleted");
        })
          .done(function () {
            $(document).trigger("searchDone");
          })
          .always(function () {
            $(document).trigger("searchFinished");
          });
      } else if (q == "") {
        $(this).on("focus", function () {
          $(".rnJq-c-search-results, .rnJq-pick-results").hide();
        });
        $(".rnJq-c-search-results, .rnJq-pick-results").hide();
      } else {
        var res = parent.children(".rnJq-pick-results");
        if (res.length < 1) {
          var res = parent.children(".rnJq-c-search-results");
        }
        res.html("");
        $("[name=join_group]").hide();
        $(".rnJq-c-search-results, .rnJq-pick-results").hide();
      }
    }
  );

  $(".search_form_button").on("click", function (e) {
    var q = $($(this).parent().children(".search-input")[0]).val();
    console.log(q);
    var parent = $(this).closest(".search_form, .team_search_form");
    var uri = parent.data("action");
    var uri = uri + q;
    var show = parseInt(parent.data("show"));

    if (show === parseInt(parent.data("show"), 10) || !isNaN(show)) {
    } else {
      show = 3;
    }

    if (q.length > 2) {
      $.getJSON(uri, function (data) {
        var items = [];
        $.each(data, function (key, val) {
          var this_id = val.id;
          var main_div = '<tr data-id="' + val.id + '">';
          var addi =
            '<tr data-id="' +
            val.id +
            '" class="rnJq-hidden" style="display:none;"><td>';
          var count = 1;

          for (var column_heading in val) {
            var value_to_use = val[column_heading];

            if (column_heading != "id" && count > show) {
              //put into output but hidden mname attribute to help find
              //would be maybe do better with a meta cell and all values in a data attribute?
              addi +=
                "<span name='" +
                column_heading +
                "' id='" +
                column_heading +
                "-" +
                this_id +
                "'>" +
                value_to_use +
                "</span>";
            } else if (column_heading != "id" && count <= show) {
              main_div +=
                "<td name='" + column_heading + "'>" + value_to_use + "</td>";
              count++;
            }
          }

          addi += "</td></tr>";
          main_div += "</tr>";
          items.push(main_div);
          items.push(addi);
          items.push("<tr></tr>");
        });
        $(this).on("focus click", function () {
          $(".rnJq-c-search-results, .rnJq-pick-results").show();
        });
        var res = parent.children(".rnJq-pick-results").show();

        if (res.length < 1) {
          var res = parent.children(".rnJq-c-search-results").show();
        }

        res.empty();
        $("<table />", {
          class: "ui simple striped table autocomplete-results",
          html: items.join(),
        }).appendTo(res);
        $(document).trigger("searchCompleted");
      })
        .done(function () {
          $(document).trigger("searchDone");
        })
        .always(function () {
          $(document).trigger("searchFinished");
        });
    } else if (q == "") {
      $(this).on("focus", function () {
        $(".rnJq-c-search-results, .rnJq-pick-results").hide();
      });
      $(".rnJq-c-search-results, .rnJq-pick-results").hide();
    } else {
      var res = parent.children(".rnJq-pick-results");

      if (res.length < 1) {
        var res = parent.children(".rnJq-c-search-results");
      }

      res.html("");
      $("[name=join_group]").hide();
      $(".rnJq-c-search-results, .rnJq-pick-results").hide();
    }
  });

  var $resWidth = $(".search_form, .team_search_form").width();

  $(".rnJq-c-search-results, .rnJq-pick-results").css("width", $resWidth);

  $(
    "body:not(.search_form input), body:not(.team_search_form input), body:not(.rnJq-c-search-results), body:not(.rnJq-pick-results)"
  ).on("mouseup", function (e) {
    $(".rnJq-c-search-results, .rnJq-pick-results").hide();
  });

  // Clicking on a row in the search results //
  $(".search_form, .team_search_form").on("click", "tr", function (ev) {
    ev.preventDefault();

    if (
      $(this).data("id") &&
      $(this).parents(".search_form, .team_search_form").data("link")
    ) {
      window.location.href =
        $(this).parents(".search_form, .team_search_form").data("link") +
        $(this).data("id");
    }
  });

  $("#allow_sponsorship, #allow_donation").change(function (e) {
    if (
      $("#allow_sponsorship").is(":checked") ||
      $("#allow_donation").is(":checked")
    ) {
      $(".race-edit-spo-don-options:hidden").fadeIn();
    }

    if (
      !$("#allow_sponsorship").is(":checked") &&
      !$("#allow_donation").is(":checked")
    ) {
      $(".race-edit-spo-don-options:visible").fadeOut();
    }

    if ($("#allow_sponsorship").is(":checked")) {
      $(".race-edit-spo-options:hidden").fadeIn();
    } else {
      $(".race-edit-spo-options:visible").fadeOut();
    }
  });

  /* -------------------------

    App Maps Specific shared JS

    ------------------------- */

  /* ------------------------------------------------------------------------------------------------------------------------

    Event Live Modal - confirm if no theme or charity set up, else event live / offline notification modal

    ------------------------------------------------------------------------------------------------------------------------ */

  $(document).on("change", ".live_flipper", function (e) {
    var live_action = $(this).attr("data-action");
    var live_target = $(this);
    var live_val = live_target.val();
    var has_charity = $(this).attr("data-has-charities");
    var charity_link = $(this).attr("data-spon-link");
    var has_theme = $(this).attr("data-has-theme");
    var theme_link = $(this).attr("data-theme-link");
    var been_live = $(this).attr("data-been-live");
    var has_tickets = $(this).attr("data-has-tickets");
    var has_payment = $(this).attr("data-has-payment");
    var event_id = $(this).attr("data-event-id");
    var payment_link = $(this).attr("data-payment-link");
    var is_free = $(this).attr("data-is-free");

    $("#event-live-modal")
      .modal({ dimmerSettings: { opacity: 0.6 }, closable: false })
      .modal("show");

    $("#event-live-modal .content p.event-status").html(
      '<div class="ui active inverted dimmer"><div class="ui text loader">Updating Event Status</div></div>'
    );

    if ($(".live_flipper").is(":checked")) {
      // If no tickets and no payment have been set up

      if (has_payment == "0" && has_tickets == "0") {
        $("#event-live-modal .content p:first-child").html(
          '<i class="alert icon"></i>&nbsp;&nbsp;&nbsp;Sorry, you can\'t go live with your event just yet.' +
            " Please make sure you've added at least one ticket and your payment information, if you're charging for tickets."
        );
        $("#event-live-modal")
          .modal({ dimmerSettings: { opacity: 0.6 } })
          .modal("show");
        $("#event-live-modal .actions").html(
          '<div class="ui ok not-live button">OK</div>'
        );
      }

      // If ticket has been added, event doesn't have a free ticket and no payment details have been added
      else if (has_payment == "0" && has_tickets == "1" && is_free == "0") {
        $("#event-live-modal .content p:first-child").html(
          '<i class="alert icon"></i>&nbsp;&nbsp;&nbsp;Sorry! You can\'t go live with your event just yet.' +
            " Please make sure you've added your payment information."
        );
        $("#event-live-modal")
          .modal({ dimmerSettings: { opacity: 0.6 } })
          .modal("show");
        $("#event-live-modal .actions").html(
          '<div class="ui ok not-live button">OK</div>'
        );
      }

      // If no tickets have been added
      else if (has_tickets == "0" && (has_payment == "1" || is_free == "1")) {
        $("#event-live-modal .content p:first-child").html(
          '<i class="alert icon"></i>&nbsp;&nbsp;&nbsp;Sorry! You can\'t go live with your event just yet.' +
            " Please make sure you've added at least one ticket"
        );
        $("#event-live-modal")
          .modal({ dimmerSettings: { opacity: 0.6 } })
          .modal("show");
        $("#event-live-modal .actions").html(
          '<div class="ui ok not-live button">OK</div>'
        );
      }

      // If neither have been set up but event is collecting for charity, payment info has been setup AND tickets have been created
      else if (
        has_charity == "0" &&
        has_theme == "0" &&
        has_tickets == "1" &&
        (has_payment == "1" || is_free == "1")
      ) {
        $(".live-message").html(
          "You’re almost there! As you’ve selected to raise money for charity, you will first need to select which charities you’re fundraising for."
        );
        $("#event-live-modal .content p.event-status").hide();
        $("#event-live-modal .actions").html(
          '<div class="ui negative ok button cancel-live">Cancel</div><a class="ui button" href="' +
            charity_link +
            '">Fundraising Options</a>'
        );
      }

      // If event isn't collecting for charity and theme hasn't been set up
      else if (
        has_charity == "" &&
        has_theme == "0" &&
        has_tickets == "1" &&
        (is_free == "1" || has_payment == "1")
      ) {
        $(".live-message").html(
          "Making your event live will make the page available to the public.<br>Are you ready? <strong>Please note:</strong> as you’ve not updated your event branding, we will use our " +
            "default banner and colour scheme. Don’t worry, you can update this in the Event Info section at any point or click on <a class='ui small compact button' href='" +
            theme_link +
            "'>Event Theme</a> to brand up your event now"
        );
        $("#event-live-modal .content p.event-status").hide();
        $("#event-live-modal .actions").html(
          '<div class="ui negative ok button cancel-live">Cancel</div><div class="ui button make-event-live">Make Live</div>'
        );
      }

      // If evcent is collecting for charity and they've not been selected / set up but theme has been set
      else if (
        has_charity == "0" &&
        has_theme == "1" &&
        has_tickets == "1" &&
        (is_free == "1" || has_payment == "1")
      ) {
        $(".live-message").html(
          "You’re almost there! As you’ve selected to raise money for charity, you will first need to select which charities you’re fundraising for."
        );
        $("#event-live-modal .content p.event-status").hide();
        $("#event-live-modal .actions").html(
          '<div class="ui negative ok button cancel-live">Cancel</div><a class="ui button" href="' +
            charity_link +
            '">Fundraising Options</a>'
        );
      }

      // If event theme hasn't been set up
      else if (
        has_theme == "0" &&
        has_charity == "1" &&
        has_tickets == "1" &&
        (is_free == "1" || has_payment == "1")
      ) {
        $(".live-message").html(
          "Making your event live will make the page available to the public.<br>Are you ready? <strong>Please note:</strong> as you’ve not updated your event branding, we will use our " +
            "default banner and colour scheme. Don’t worry, you can update this in the Event Info section at any point or click on <a class='ui small compact button' href='" +
            theme_link +
            "'>Event Theme</a> to brand up your event now"
        );
        $("#event-live-modal .content p.event-status").hide();
        $("#event-live-modal .actions").html(
          '<div class="ui negative ok button cancel-live">Cancel</div><div class="ui button make-event-live">Make Live</div>'
        );
      }

      // Otherwise make the event live like normal without a confirm
      else {
        $.post(
          live_action,
          { _token: csrftoken, state: live_val },
          function (data) {
            $("#event-live-modal .content p.event-status").html(
              'Your event is now &nbsp;&nbsp;<span class="ui green label">LIVE</span>'
            );
            // Check if event has been live before, if not serve up meaningful message
            if (been_live == "0") {
              $("#event-live-modal .content p.event-status").append(
                "<br /><small>Congratulations, your event is now set up and entrants can register!</small>"
              );
            }
            $(".is-it-live").html("");
            $('<div class="ui green label"></div>')
              .html("LIVE")
              .appendTo(".is-it-live");
            $(".live-label").text("Take event offline?");
            $("#event-live-modal .actions").html(
              '<div class="ui ok button event-live-ok">OK</div>'
            );
          }
        ).fail(function () {
          $("#event-live-modal")
            .modal({ dimmerSettings: { opacity: 0.6 } })
            .modal("show");
          $("#event-live-modal .actions").html(
            '<div class="ui ok button">OK</div>'
          );
        });
      }
    }

    // Make event 'Offline'
    else {
      $.post(
        live_action,
        { _token: csrftoken, state: live_val },
        function (data) {
          $("#event-live-modal .content p.event-status")
            .html(
              'Your event is now &nbsp;&nbsp;<span class="ui red label">OFFLINE</span>'
            )
            .show();
          $(".is-it-live").html("");
          $('<span class="ui red label"></span>')
            .html("OFFLINE")
            .appendTo(".is-it-live");
          $(".live-label").text("Make event live?");
          $("#event-live-modal .actions").html(
            '<div class="ui ok button">OK</div>'
          );
        }
      ).fail(function () {
        $("#event-live-modal .content p:first-child").html(
          '<i class="alert icon"></i>&nbsp;&nbsp;&nbsp;The event has not gone live please try again'
        );
        $("#event-live-modal")
          .modal({ dimmerSettings: { opacity: 0.6 } })
          .modal("show");
        $("#event-live-modal .actions").html(
          '<div class="ui ok button">OK</div>'
        );
      });
    }

    // If it's a confirm scenario, 'Make Live' button needs to make the event live even if charity or theme hasn't been set up

    $(".make-event-live").click(function () {
      $.post(
        live_action,
        { _token: csrftoken, state: live_val },
        function (data) {
          $(
            "#event-live-modal .content p:not(.event-status), .is-it-live"
          ).html("");
          $("#event-live-modal .content p.event-status")
            .html(
              'Your event is now &nbsp;&nbsp;<span class="ui green label">LIVE</span>'
            )
            .show();
          // Check if event has been live before, if not serve up meaningful message
          if (been_live == "0") {
            $("#event-live-modal .content p.event-status").append(
              "<br /><small>Congratulations, your event is now set up and entrants can register!</small>"
            );
          }
          $('<div class="ui green label"></div>')
            .html("LIVE")
            .appendTo(".is-it-live");
          $(".live-label").text("Take event offline?");
          $("#event-live-modal .actions").html(
            '<div class="ui ok button event-live-ok">OK</div>'
          );
          $(".live_flipper").checkbox("set checked");
        }
      ).fail(function () {
        $("#event-live-modal .content p:first-child").html(
          '<i class="alert icon"></i>&nbsp;&nbsp;&nbsp;The event has not gone live please try again'
        );
        $("#event-live-modal .content p:first-child").show();
        $("#event-live-modal")
          .modal({ dimmerSettings: { opacity: 0.6 } })
          .modal("show");
      });
    });

    // Deny button action - return checkbox toggle UI to 'off'

    $(".cancel-live, .not-live.button").click(function () {
      if ($(".live_flipper").is(":checked")) {
        $(".live_flipper").prop("checked", false);
      }
    });
  });

  // If event hasn't been live before, refresh the current page to get the other widgets loaded in
  $(document).on("click", ".event-live-ok", function () {
    if ($(".live_flipper").length) {
      var been_live = $(".live_flipper").attr("data-been-live");
      if (been_live == "0") {
        location.reload();
      }
    }
  });

  /*******************
     End event live modal
     *******************/

  $(document).on("click", "#email_post", function (e) {
    var live_action = $(this).data("action");
    var email_input = $("#email_input").val();
    var org = $(this).data("org");

    $.post(
      live_action,
      {
        _token: csrftoken,
        email: email_input,
        organiser_id: org,
        is_charity: $(this).data("is_charity"),
      },
      function (data) {
        if (data.hasOwnProperty("message")) {
          if (data.message == "User found") {
            $(".search_email").hide();
            $("#invite_email_output").val(email_input);
            $(".email_invite .invite_text").html(
              "The user with email " +
                email_input +
                " exists in the system, would you like to send them an invitation to join your organisation?"
            );
            $(".email_invite").show();
          } else if (data.message == "User not found") {
            $(".search_email").hide();
            $("#email_output").val(email_input);
            $(".create_result").show();
            getFields();
            validateForm();
          }
        }
      }
    ).fail(function (data) {
      // prints messages from the validator
      for (var item in data.responseJSON) {
        toastr.error(data.responseJSON[item]);
      }
    });
  });

  /* image upload needs to be handled separately for merchandise pages
       See resources/views/organiser/merchandise/new_partials/merch_details.blade.php */

  if ($(".merchandise-edit").length == 0) {
    // check we're not on the merchandise edit view

    // Variable to store your files
    var files;
    // Add events
    $("input[type=file]").on("change", prepareUpload);

    $(".uploadForm").on("submit", uploadFiles);

    // Make file upload fields look a bit nicer: <input type="file"> is hidden but triggred when 'fake' text input is clicked on

    // When user clicks on browse button
    $(".browse-file").each(function () {
      var filename = $(this).parent().find("input:file").val();

      $(this).on("click", function (e) {
        $(e.currentTarget).parent().find("input:file").trigger("click");
        $(e.currentTarget).parent().find(".form-messages").fadeOut();
      });
    });

    // When user selects a file (when a different file to the one selected is chosen) show the text field contain file name, make upload button appear
    $(document).on(
      "change",
      ".uploadForm input:file, .file-uploader input:file",
      function (e) {
        var name = e.target.files[0].name;
        $(e.currentTarget)
          .parent()
          .find($("input:text", $(e.target).parent()))
          .val(name)
          .transition("slide right in")
          .show();
        $(e.currentTarget)
          .parent()
          .find($(".file-name", $(e.target).parent()))
          .val(name);
        $(e.currentTarget)
          .parent()
          .find(".fi-upload")
          .transition("slide down in")
          .show();
        $(e.currentTarget)
          .parents(".upload-elems")
          .show()
          .find(".fi-upload")
          .attr("class", "ui navy right labeled icon small button fi-upload");
      }
    );

    // Make loading animation appaear on upload button when pressed
    $(document).on("click", "button.fi-upload", function (e) {
      $(e.currentTarget).attr(
        "class",
        "ui navy right labeled icon small loading button fi-upload"
      );
    });
  }

  // TINY MCE INIT
  var rnTinyMceOptions;
  if (typeof rnUserLevel !== "undefined" && rnUserLevel === 10) {
    rnTinyMceOptions = {
      selector: ".rte",
      menubar: true,
      remove_script_host: false,
      relative_urls: false,
      statusbar: false,
      height: "260",
      plugins: "code, image, link, paste",
      image_advtab: true,
      theme: "modern",
      skin: "racenation",
      content_css: "/css/public.css",
      content_style:
        "p {font-family: Helvetica, Arial, sans-serif !important; font-size: 14px}",
    };
  } else {
    rnTinyMceOptions = {
      selector: ".rte",
      menubar: false,
      statusbar: false,
      height: "260",
      plugins: "paste",
      theme: "modern",
      skin: "racenation",
      content_css: "/css/public.css",
      content_style:
        "p {font-family: Helvetica, Arial, sans-serif !important; font-size: 14px}",
    };
  }
  if (typeof tinymce != "undefined") {
    tinymce.init(rnTinyMceOptions);
  }

  $(document).on("click", ".confirm-alert", function (ev) {
    ev.preventDefault();
    var $btn = $(this);

    $("#confirm-modal .content > p").html($btn.attr("data-question"));
    $("#confirm-modal")
      .modal({
        blurring: false,
        inverted: true,
        closable: false,
        onShow: function () {
          if ($(ev.currentTarget).data("cancelbtn-text")) {
            var cancel_text = $(ev.currentTarget).data("cancelbtn-text");
            $(this).find(".actions .cancel.button").text(cancel_text);
          }
          if ($(ev.currentTarget).data("okbtn-text")) {
            var ok_text = $(ev.currentTarget).data("okbtn-text");
            $(this).find(".actions .ok.button").text(ok_text);
          }
        },
        onDeny: function () {
          return true;
        },
        onApprove: function () {
          if ($btn.attr("href")) {
            window.location.href = $btn.attr("href");
          } else {
            $btn.closest("form").submit();
          }
        },
      })
      .modal("show");
  });

  $(document).on("click", ".confirm-delete", function (ev) {
    ev.preventDefault();
    var $btn = $(this);

    $("#delete-modal .content > p").html($(this).data("question"));
    $("#delete-modal")
      .modal({
        blurring: false,
        inverted: true,
        closable: false,
        onShow: function () {
          if ($(ev.currentTarget).data("cancelbtn-text")) {
            var cancel_text = $(ev.currentTarget).data("cancelbtn-text");
            $(this).find(".actions .cancel.button").html(cancel_text);
          }
          if ($(ev.currentTarget).data("okbtn-text")) {
            var ok_text = $(ev.currentTarget).data("okbtn-text");
            $(this).find(".actions .ok.button").text(ok_text);
          }
        },
        onDeny: function () {
          return true;
        },
        onApprove: function () {
          if ($btn.attr("href")) {
            window.location.href = $btn.attr("href");
          } else {
            $btn.closest("form").submit();
          }
        },
      })
      .modal("show");
  });

  $(".show-optional").change(function () {
    if ($(this).is(":checked")) {
      $(this).closest("div.field").next(".optional-field:hidden").fadeIn();
    } else {
      $(this).closest("div.field").next(".optional-field:visible").fadeOut();
      $(this)
        .closest("div.field")
        .find('.optional-field input[type="checkbox"]')
        .prop("checked", false);
      $(this)
        .closest("div.field")
        .find(
          '.optional-field input[type="text"], .optional-field input[type="number"]'
        )
        .val("");
    }
  });

  $(".hide-optional").change(function () {
    if ($(this).is(":checked")) {
      $(this).closest(".field").next(".optional-field:visible").fadeOut();
      $(this)
        .closest("div.field")
        .find('.optional-field input[type="checkbox"]')
        .prop("checked", false);
      $(this)
        .closest("div.field")
        .find(
          '.optional-field input[type="text"], .optional-field input[type="number"]'
        )
        .val("");
    } else {
      $(this).closest("div.field").next(".optional-field:hidden").fadeIn();
    }
  });

  // Semantic UI Quirk for displaying cached checboxes that hide other fields
  var hide_optional = $(".hide-optional").parent();
  if (hide_optional.checkbox("is checked")) {
    hide_optional.closest(".field").next(".optional-field:visible").hide();
    hide_optional
      .closest("div.field")
      .find('.optional-field input[type="checkbox"]')
      .prop("checked", false);
    hide_optional
      .closest("div.field")
      .find(
        '.optional-field input[type="text"], .optional-field input[type="number"]'
      )
      .val("");
  } else {
    hide_optional.closest(".field").next(".optional-field:hidden").show();
  }

  /*
   * simple show hide which takes shows or hides an element
   * which has the same id as the data-tab value which is clicked
   */
  $(".show-hide-simple").click(function () {
    if ($("#" + $(this).data("tab")).is(":visible")) {
      $("#" + $(this).data("tab")).hide();
    } else {
      $("#" + $(this).data("tab")).show();
    }
  });

  $(".optional-affiliated .show-optional").change(function () {
    if ($(this).is(":checked")) {
      $(this).closest("div.field").next(".optional-field:hidden").fadeIn();
      $(this)
        .closest("div.field")
        .find('.optional-field input[type="checkbox"]')
        .prop("checked", true);
    } else {
      $(this).closest("div.field").next(".optional-field:visible").fadeOut();
      $(this)
        .closest("div.field")
        .find('.optional-field input[type="checkbox"]')
        .prop("checked", false);
    }
  });

  // Add new pricing rules rows to table on ticket create page

  $(
    "tr#row0 .sale_from_date, tr#row0 .sale_to_date, .sale_to_date, .sale_from_date"
  ).calendar({
    type: "datetime",
    ampm: false,
    formatter: {
      datetime: function (date, settings) {
        if (!date) return "";
        var hours =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        var mins =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins;
      },
    },
  });

  //should the below variable beinsde the following function?
  var i = 1; // Start at 1 because the first row is already there and has an ID of "row0"

  $(".add-new-cp-row").on("click", function (e) {
    // Set click event on add row button as a global dom event so that we can set clone() to false
    e.preventDefault();
    var index = i++;

    $(this)
      .closest("tr")
      .prev()
      .clone(false)
      .attr("id", "row" + index)
      .attr("data-id", index)
      .insertBefore($(this).closest("tr"))
      .addClass("new")
      .find("input.rule_title")
      .val("");
    $("tr.new").find('input[name="_token"]').remove();
    $("tr.new").find('input[name="title"]').val("");

    var current = parseInt($(this).closest("tr").data("id"));

    $(this)
      .closest("tr#row" + current)
      .find(".sale_to_date, .sale_from_date")
      .calendar({
        type: "datetime",
        ampm: false,
        formatter: {
          datetime: function (date, settings) {
            if (!date) return "";
            var hours =
              date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var mins =
              date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes();
            return (
              $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins
            );
          },
        },
      });

    if (index != current) {
      $("tr#row" + index)
        .find(".sale_to_date, .sale_from_date")
        .calendar({
          type: "datetime",
          ampm: false,
          formatter: {
            datetime: function (date, settings) {
              if (!date) return "";
              var hours =
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
              var mins =
                date.getMinutes() < 10
                  ? "0" + date.getMinutes()
                  : date.getMinutes();
              return (
                $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins
              );
            },
          },
        });
    }

    //$(this).hide();

    $(this)
      .closest("tr")
      .prev()
      .find("td:first-child")
      .html(
        '<button class="ui negative icon button remove-new-cp-row info-popup" data-content="Delete this price rule"><i class="minus icon"></i></button>'
      )
      .on("click", function (e) {
        e.preventDefault();
        $(this).closest("tr").remove();
      });

    $(this)
      .parent()
      .parent()
      .prev()
      .find("td")
      .eq(1)
      .find("inputrule_title")
      .val("");
  });

  if ($(".delete-rule").length) {
    $(".delete-rule").eq(0).prop("disabled", true);
  }

  $(document).ajaxComplete(function () {
    $(".remove-new-cp-row").on("click", function (e) {
      e.preventDefault();
      $(this).closest("tr").remove();
    });
  });

  $("#ticket_save").on("click", function (e) {
    e.preventDefault();

    $("tr.new").find("input.rule_id").val("");

    $("#wizard_ticket_form").submit();
  });

  // Race Create - Type of event select other value //
  $("#event_type").change(function (fields) {
    if ($(this).find("option:selected").val() == "Other") {
      $(".field_event_type_other:hidden")
        .fadeIn()
        .find("input")
        .val("")
        .prop("required", true);
      $(".field_event_type_other").addClass("required");
      getFields();
      validateForm();
    } else {
      $(".field_event_type_other:visible").fadeOut("fast", function () {
        $(".field_event_type_other")
          .find("input")
          .val($("#event_type option:selected").val())
          .prop("required", false);
      });
      $(".field_event_type_other").removeClass("required");
      $(".field_event_type_other input").trigger("input");
      validateForm();
      getFields();
    }
  });

  // Race entry view - update tshirt size //
  $("#post_tshirt").click(function (ev) {
    ev.preventDefault();
    var $btn = $(this);
    $btn.html("Save").addClass("loading");
    $.ajax({
      url: $btn.attr("href"),
      dataType: "json",
      method: "PUT",
      data: "value=" + $("#tshirt_size").val() + "&_token=" + csrftoken,
      success: function () {
        $btn
          .addClass("success")
          .removeClass("loading")
          .prepend('<i class="checkmark icon"></i>')
          .append("d");
        setTimeout(function () {
          $btn.removeClass("success");
        }, 4000);
      },
      error: function () {
        $btn.addClass("alert");
        setTimeout(function () {
          $btn.removeClass("alert");
        }, 4000);
      },
    });
  });
  // Help and Tips trigger

  $(".helper-action").on("click", function (e) {
    e.stopPropagation(); // Stop the helper action from triggering the change event on the corresponding form element

    var helpTitle = $(this).parent().text();
    var helpText = $(this).attr("data-helper-text");

    $(
      '<div class="item"><div class="header">' +
        helpTitle +
        '</div><div class="half-spacer"></div>' +
        helpText +
        "</div>"
    ).prependTo(".ui.sidebar.tips .list");

    $(".ui.sidebar.tips")
      .sidebar("setting", "transition", "overlay")
      .sidebar("setting", "dimPage", "false")
      .sidebar("toggle");
  });

  $(".info-popup").on("click", function (e) {
    e.stopPropagation(); // Stop the helper action from triggering the change event on the corresponding form element
  });

  // Clear the sidebar of its content when closed

  $(".ui.sidebar.tips").sidebar({
    onHidden: function () {
      $(".ui.sidebar.tips .list").html("");
    },
  });

  $("#close-tips").click(function () {
    $(".ui.sidebar.tips").sidebar("hide");
  });

  // Race entry view - update ice info //
  $(".post-ice").click(function (ev) {
    ev.preventDefault();
    var $btn = $(this);
    $btn.html("Save").addClass("loading");

    $.ajax({
      url: $btn.attr("href"),
      dataType: "json",
      method: "PUT",
      data:
        "ice_name=" +
        $("#ice_name").val() +
        "&ice_number=" +
        $("#ice_num").val() +
        "&_token=" +
        csrftoken,
      success: function () {
        $btn
          .addClass("success")
          .removeClass("loading")
          .prepend('<i class="checkmark icon"></i>')
          .append("d");
        setTimeout(function () {
          $btn.removeClass("success");
        }, 4000);
      },
      error: function () {
        $btn.addClass("alert");
        setTimeout(function () {
          $btn.removeClass("alert");
        }, 4000);
      },
    });
  });

  // Race entry view - update affilated club //
  $("#affiliated_to").change(function () {
    if ($(this).val() == "other") {
      $("#affiliated_other").show();
    } else {
      $("#affiliated_other").hide();
    }
  });

  $("#post_affiliated").click(function (ev) {
    ev.preventDefault();
    var $btn = $(this);
    $btn.html("Save").addClass("loading");

    $.ajax({
      url: $btn.attr("href"),
      dataType: "json",
      method: "PUT",
      data:
        "value=" +
        $("#affiliated_to").val() +
        "&other=" +
        $("#affiliated_other_value").val() +
        "&_token=" +
        csrftoken,
      success: function () {
        $btn
          .addClass("success")
          .removeClass("loading")
          .prepend('<i class="checkmark icon"></i>')
          .append("d");
        setTimeout(function () {
          $btn.removeClass("success");
        }, 4000);
      },
      error: function () {
        $btn.addClass("alert");
        setTimeout(function () {
          $btn.removeClass("alert");
        }, 4000);
      },
    });
  });

  // Race entry view - update chosen charity //
  //hide this on page load
  $("#entry-charity-select").hide();

  $("#entry-chosen-charity-show").click(function () {
    $("#entry-chosen-charity").hide();
    $("#entry-charity-select").show();
  });

  $("#entry-chosen-charity-hide").click(function () {
    $("#entry-chosen-charity").show();
    $("#entry-charity-select").hide();
  });

  $("#post_charity").click(function (ev) {
    ev.preventDefault();
    var $btn = $(this);
    $btn.html("Save").addClass("loading");

    $.ajax({
      url: $btn.attr("href"),
      dataType: "json",
      method: "PUT",
      data: "value=" + $("#chosen_charity").val() + "&_token=" + csrftoken,
      success: function () {
        $btn
          .addClass("success")
          .removeClass("loading")
          .prepend('<i class="checkmark icon"></i>')
          .append("d");
        $("#entry-chosen-charity-text").text(
          $("#chosen_charity option:selected").text()
        );
        $("#entry-chosen-charity").show();
        $("#entry-charity-select").hide();
        setTimeout(function () {
          $btn.removeClass("success");
        }, 4000);
      },
      error: function () {
        $btn.addClass("alert");
        setTimeout(function () {
          $btn.removeClass("alert");
        }, 4000);
      },
    });
  });

  function uncheck() {
    $(".toggle-sponsor-disable").checkbox("set unchecked");
  }

  function check() {
    $(".toggle-sponsor-disable").checkbox("set checked");
  }

  // Race entry view - update sponsorship enabled / disabled

  $(function () {
    $(".view-password")
      .on("click", function () {
        var pword = $(this).parent().find('input[name^="password"]');
        if (pword.attr("type") === "password") {
          pword.attr("type", "text");
          $(this).addClass("orange");
        } else {
          pword.attr("type", "password");
          $(this).removeClass("orange");
        }
      })
      .css({ cursor: "pointer", "line-height": "1.2142em" });
    // Needs to run inside document ready, waits for Semantic UI JS to innit

    // Table sorting for tables with pagination

    $("table.rn-sort thead th a").each(function () {
      sortOrder = $(this).attr("class");

      if ($(this).hasClass(sortOrder)) {
        $(this)
          .parent()
          .addClass("sorted " + sortOrder);
      }

      var action = $(this).attr("href");

      $(this)
        .parent()
        .click(function () {
          window.location.href = action;
        });
    });

    $(document).on("change", 'input[name="sponsor_disable"]', function () {
      var $btn = $(this);

      if (
        confirm(
          "Are you sure you want to change this entrant's sponsorship status?"
        )
      ) {
        $btn
          .parent()
          .find("label")
          .html('<div class="ui active tiny inline loader"></div>');
        $.ajax({
          url: $btn.attr("data-link"),
          dataType: "json",
          method: "PUT",
          data: "_token=" + csrftoken,
          success: function (data) {
            $btn.parent().find("label").html("Enable sponsorship page?");
            $btn.removeClass(data.remove_class);
            $btn.addClass(data.add_class);
          },

          error: function () {
            $btn.addClass("alert");
            setTimeout(function () {
              $btn.removeClass("alert");
            }, 4000);
          },
        });
      } else {
        if ($(this).is(":checked")) {
          uncheck();
        } else {
          check();
        }
      }
    });

    // Race entry view, show medical info //
    $("#show_medical").on("click", function (ev) {
      ev.preventDefault();
      var $btn = $(this);

      $(".ui.modal.medical_confirm")
        .modal({
          closable: false,
          onDeny: function () {
            $(this).modal("hide");
            return false;
          },
          onApprove: function () {
            $(this).modal("hide");
            $.ajax({
              url: $btn.attr("href"),
              dataType: "json",
              success: function (data) {
                if (data.content == "" || data.content == null) {
                  data.content = "No medical data stored";
                }

                $("#medical_wrapper")
                  .html('<div class="ui message">' + data.content + "</div>")
                  .slideDown()
                  .delay(60000)
                  .slideUp(function () {
                    $(this).empty();
                  });
              },
              error: function () {
                $btn.addClass("alert");
                setTimeout(function () {
                  $btn.removeClass("alert");
                }, 4000);
              },
            });
          },
        })
        .modal("show");
    });

    $("#show_medical_report").on("click", function (ev) {
      ev.preventDefault();
      var $btn = $(this);

      $(".ui.modal.medical_confirm")
        .modal({
          closable: false,
          onDeny: function () {
            $(this).modal("hide");
            return false;
          },
          onApprove: function () {
            $(this).modal("hide");
            var href = $("#show_medical_report").attr("href");
            window.location.href = href;
          },
        })
        .modal("show");
    });

    // Mobile optimisation JS

    var isTouchDevice = "ontouchstart" in document.documentElement;

    if (!isTouchDevice) {
      // Semantic UI Dropdown init

      $("select#on_sale_to, select#on_sale_from, select#race_ticket_class_id")
        .addClass("ui dropdown")
        .dropdown();

      $(
        ".ui.dropdown:not(.ui.dropdown.lang-selector):not(.ui.dropdown.allow-blank):not(.no-default)"
      ).dropdown({
        forceSelection: false,
      });

      $(".ui.dropdown.allow-blank").dropdown({
        placeholder: false,
      });

      $(".ui.dropdown.multi-test.multiple").dropdown({
        allowReselection: true,
        forceSelection: false,
      });
    } else {
      $(".ui.dropdown.organiser-header-menu").dropdown();
      //$('.ui.right.floated.button:last-child').after('<div class="clearfix"></div>');
    }

    $(".more-items").dropdown();

    // Semantic UI Checkbox init

    $(".ui.checkbox").checkbox();

    // Sortable tables init

    $("table.sortable").tablesort();

    // Make all tables sortable

    $(".ui.table:not(.orders-table, .not-sortable)").addClass("sortable");

    // Super Admin menu dropdown in event details top menu

    $(".ui.dropdown.sa-menu").dropdown({
      on: "hover",
      action: "nothing",
    });

    // Semantic Accordion init

    $(".ui.acordion").accordion();

    // Semantic Popup init

    $(".info-popup").popup();

    // Colour Picker
    if ($(".color").length > 0) {
      $(".color").colorPicker({
        customBG: "#222",
        size: 1,
        noAlpha: true,
        readOnly: false,
        init: function (elm, colors) {
          // colors is a different instance (not connected to colorPicker)
          elm.style.backgroundColor = elm.value;
          elm.style.color =
            colors.rgbaMixCustom.luminance > 0.22 ? "#222" : "#ddd";
        },
      });
    }

    // Add upload icon to upload buttons

    $("button.fi-upload").addClass("ui icon button");
    if ($("button").text() == "Upload") {
      $(this).prepend('<i class="upload icon"></i>');
    }

    // Date picker (uses /public/js/bower_components/semantic-ui-calendar)

    $(".datetime-pick").calendar({
      type: "datetime",
      ampm: false,
      formatter: {
        datetime: function (date, settings) {
          if (!date) return "";
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var mins =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          return $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins;
        },
      },
    });

    $(".discounts-datetime-pick").calendar({
      ampm: false,
      type: "datetime",
      formatter: {
        datetime: function (date, settings) {
          if (!date) return "";
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var mins =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          return $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins;
        },
      },
    });

    $(".date-pick, .dob, .date").calendar({
      type: "date",
      firstDayOfWeek: 1,
      formatter: {
        date: function (strDate, settings) {
          if (strDate != undefined) {
            return $.format.date(strDate, "D MMM yyyy");
          }
        },
      },
    });

    // Set calendars for event dates to have a range. Make only dates after or same as start date selectable

    var today = new Date();
    $(".start-date").calendar({
      type: "date",
      minDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      ),
      formatter: {
        date: function (strDate, settings) {
          if (strDate != undefined) {
            return $.format.date(strDate, "D MMM yyyy");
          }
        },
      },
    });

    $(".end-date").calendar({
      type: "datetime",
      minDate: $(".start-date").calendar("get date"),
      ampm: false,
      formatter: {
        datetime: function (date, settings) {
          if (!date) return "";
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var mins =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          return $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins;
        },
      },
      startCalendar: $(".start-date"),
    });

    // Report date ranges

    $(".rep-start-date").calendar({
      type: "date",
      formatter: {
        date: function (strDate, settings) {
          if (strDate != undefined) {
            return $.format.date(strDate, "D MMM yyyy");
          }
        },
      },
      maxDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes()
      ),
      endCalendar: $(".rep-end-date"),
    });

    $(".rep-end-date").calendar({
      type: "date",
      formatter: {
        date: function (strDate, settings) {
          if (strDate != undefined) {
            return $.format.date(strDate, "D MMM yyyy");
          }
        },
      },
      maxDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes()
      ),
      startCalendar: $(".rep-start-date"),
    });

    // Summary page date ranges (start date should be changeable but end date should only be after start date)

    $(".summary-start-date").calendar({
      type: "datetime",
      ampm: false,
      formatter: {
        datetime: function (date, settings) {
          if (!date) return "";
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var mins =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          return $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins;
        },
      },
      endCalendar: $(".summary-end-date"),
    });

    $(".summary-end-date").calendar({
      type: "datetime",
      ampm: false,
      formatter: {
        datetime: function (date, settings) {
          if (!date) return "";
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var mins =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          return $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins;
        },
      },
      startCalendar: $(".summary-start-date"),
    });

    // Registration close date is limited to before the event finishes

    $(".reg-closes").calendar({
      type: "datetime",
      ampm: false,
      formatter: {
        datetime: function (date, settings) {
          if (!date) return "";
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var mins =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          return $.format.date(date, "D MMM yyyy") + ", " + hours + ":" + mins;
        },
      },
      maxDate: $(".summary-end-date").calendar("get date"),
    });

    // Remove browser autocomplete for date fields

    $(
      ".date, .summary-start-date, .summary-end-date, .datetime, .date-pick, .dob, .start-date, .end-date"
    ).each(function () {
      var $this = $(this).find("input");
      $this.attr("autocomplete", "off");
    });

    $("#stripe_connect_form").on("click", 'input[name="store"]', function (ev) {
      if ($(this).val() == "race") {
        $("#div_stripe_login:hidden").slideDown();
      } else {
        $('input[name="account"]').removeAttr("checked");
        $("#div_stripe_login:visible").slideUp();
      }
    });

    $("#fundraising_provider").on("change", function (ev) {
      console.log("Activated");
      if ($(this).val() != "SG") {
        $("#fundraising_container, .provider-name, .ui.orange.messsage").fadeIn(
          "fast"
        );
      } else {
        $("#fundraising_container, .provider-name").fadeOut("fast");
      }
    });

    $('.fundraising-provider:contains("SG")').text("SportsGiving");
    $('.fundraising-provider:contains("O")').text("Other");

    $("#search_value").on("input", function () {
      if ($(this).val() == 0) {
        $(".no-results").hide();
      }
    });

    $(".message .close").on("click", function () {
      $(this).closest(".message").transition("fade");
    });

    $(".merchandise-edit .message.alert-info .close").one("click", function () {
      $(this).closest(".message.alert-info").transition("fade").remove();
    });

    $("#fundraising_search").click(function (ev) {
      var charityName = $("#search_value").val();
      $(".charity-name").text(charityName);
      $(".no-results").hide();
      //remove everything from select box
      $("#fundraising_id").find("option").remove().end();
      $.ajax({
        url: "/fundraising/search",
        dataType: "json",
        type: "GET",
        data: {
          search_type: $("#search_type").val(),
          search_value: $("#search_value").val(),
          provider: $("#fundraising_provider").val(),
        },
        beforeSend: function () {
          $(".search_progress").show();
        },
        success: function (data) {
          $(".search_progress").hide();
          var count = 0;

          $.each(data.results, function (i, item) {
            if (count == 0) {
              $("#fundraising_title").val(item);
              $(".search_progress").hide();
            }
            $("#fundraising_id").append(
              $("<option>", {
                value: i,
                text: item,
              }),
              "</option>"
            );
            count++;
          });

          if (count > 0) {
            $("#fundraising_results").fadeIn("fast");
            $(".no-results").fadeOut("fast");
          } else {
            $("#fundraising_results").fadeOut("fast");
            $(".no-results").fadeIn("fast");
            $(".search_progress").hide();
          }
        },
        error: function (data) {
          $(".no-results").fadeIn("fast");
          console.log("Error returned: ");
          console.log(data);
          $("#fundraising_results").fadeOut("fast");
          $(".ui.loader").hide();
        },
      });
    });

    $("#fundraising_id").on("change", function (ev) {
      $("#fundraising_title").val($(this).find("option:selected").text());
    });

    //Generic Ajax submit function
    //Could do with a little tiding up
    //add class ajax_submit to form, and classes
    //ajax_reponse_success and ajax_reponse_error where ypu want reponse messages displayed
    $(".ajax_submit").on("submit", function (e) {
      var theForm = $(this);
      //disable all submit buttons and show loading icon
      theForm
        .find('[type="submit"]')
        .addClass("loading")
        .prop("disabled", true);
      //make sure any currently showing messages are hidden again
      theForm.find(".ajax_reponse_success, .ajax_reponse_error").hide();
      e.preventDefault();
      $.ajax({
        url: $(this).attr("action"),
        dataType: "json",
        method: "POST",
        data: "_token=" + csrftoken + "&" + $(this).serialize(),
        success: function (content) {
          theForm.find(".ajax_reponse_success").show();
          theForm.find(".ajax_reponse_success > span").html(content.msg);
          //remove loaidng icon and reenable button
          theForm.find('[type="submit"]').removeClass("loading");
          theForm
            .find('[type="submit"]')
            .removeClass("loading")
            .prop("disabled", false);
        },
        error: function (content) {
          theForm.find(".ajax_reponse_error").show();
          theForm.find(".ajax_reponse_error > span").html(content.msg);
          //remove loaidng icon and reenable button
          theForm
            .find('[type="submit"]')
            .removeClass("loading")
            .prop("disabled", false);
        },
      });
    });

    //New generic ajax Functions
    //@TODO abstract these 3 functions into 1 or make it DRY-er atleast

    //Generic Ajax On Load
    $(".ajaxOnLoad").each(function (e) {
      //retrieve the get URL from the data attribute
      //e.preventDefault();

      var thisElement = $(this);
      var containerId = thisElement.data("success-container");
      var container = $(containerId);
      var pageUrl = thisElement.data("page-url");

      thisElement.addClass("loading");

      if (
        thisElement.data("complete") === undefined ||
        !thisElement.data("complete")
      ) {
        $.ajax({
          url: pageUrl,
          dataType: "html",
          method: "GET",
          success: function (content) {
            if (thisElement.data("modal") === 1) {
              container.modal("show");
              container
                .find(".content")
                .addClass("rn-dark ui centered")
                .html(content);
            } else {
              container.html(content);
            }

            //if it doesn't exists its because the container could be updated multiple times
            if (thisElement.data("complete") !== undefined) {
              thisElement.attr("data-complete", 1);
            }
          },
          error: function (content) {
            //display an error message in the error modal
            //.alert modal
            $(".ui.small.alert.modal").modal("show");
            $(".ui.small.alert.modal")
              .find(".content")
              .addClass("rn-dark ui centered")
              .html(content);
          },
          complete: function (content) {
            //remove loading icon and reenable button
            $(containerId).removeClass("loading");
            initFunctionsPostAjax($(containerId));
          },
        });
      }
    });

    //Generic Ajax On Load or On Click
    $(document).on("click", ".ajaxLoadOnClick", function (e) {
      //retrieve the get URL from the data attribute
      e.preventDefault();

      var thisElement = $(this);
      var containerId = thisElement.data("success-container");
      var container = $(containerId);
      var pageUrl = thisElement.data("page-url");

      thisElement.addClass("loading");

      if (
        thisElement.data("complete") === undefined ||
        !thisElement.data("complete")
      ) {
        $.ajax({
          url: pageUrl,
          dataType: "html",
          method: "GET",
          success: function (content) {
            if (thisElement.data("modal") === 1) {
              container.modal("show");
              container.addClass("rn-dark ui centered").html(content);
            } else {
              container.html(content);
            }

            //if it doesn't exists its because the container could be updated multiple times
            if (thisElement.data("complete") !== undefined) {
              thisElement.attr("data-complete", 1);
            }
          },
          error: function (content) {
            //display an error message in the error modal
            //.alert modal
            $(".ui.small.alert.modal").modal("show");
            $(".ui.small.alert.modal")
              .find(".content")
              .addClass("rn-dark ui centered")
              .html(content);
          },
          complete: function (content) {
            //remove loading icon and reenable button
            thisElement.removeClass("loading");
            //initFunctionsPostAjax(container);
          },
        });
      }
    });

    //Generic Ajax On Load or On Click
    $(document).on("submit", "form.ajaxOnSubmit", function (e) {
      //retrieve the get URL from the data attribute
      e.preventDefault();

      var thisElement = $(this);
      var containerId = thisElement.data("success-container");
      var container = $(containerId);
      var submitUrl = thisElement.attr("action");

      thisElement.addClass("loading");

      thisElement
        .find('[type="submit"]')
        .addClass("loading")
        .prop("disabled", true);

      if (
        thisElement.data("complete") === undefined ||
        !thisElement.data("complete")
      ) {
        $.ajax({
          url: submitUrl,
          dataType: "html",
          method: "POST",
          data: "_token=" + csrftoken + "&" + thisElement.serialize(),
          success: function (content) {
            if (thisElement.data("modal") === 1) {
              container.modal("show");
              container
                .find(".content")
                .addClass("rn-dark ui centered")
                .html(content);
            } else {
              container.html(content);
            }

            //if it doesn't exists its because the container could be updated multiple times
            if (thisElement.data("complete") !== undefined) {
              thisElement.attr("data-complete", 1);
            }
          },
          error: function (content) {
            //display an error message in the error modal
            //.alert modal
            $(".ui.small.alert.modal").modal("show");
            $(".ui.small.alert.modal")
              .find(".content")
              .addClass("rn-dark ui centered")
              .html(content);
          },
          complete: function (content) {
            //remove loading icon and reenable button
            thisElement.removeClass("loading");
            initFunctionsPostAjax($(container));

            thisElement
              .find('[type="submit"]')
              .removeClass("loading")
              .prop("disabled", false);
          },
        });
      }
    });

    //END New generic ajax Functions

    /*********************************************/
    /* Merchandise Edit Page Start               */
    /*********************************************/
    var display_stock_warning = function () {
      if (
        !$('input[name="unlimited"]').prop("checked") &&
        $('input[name="enable_tickets"]').prop("checked")
      ) {
        //we need to display the warning
        $("#stock_warning").show();
      } else {
        //hide warnging
        $("#stock_warning").hide();
      }
    };
    //Warn user of negative stock caused by ticket attached items
    //run function on load and on change event
    display_stock_warning();
    $('input[name="unlimited"], input[name="enable_tickets"]').change(
      display_stock_warning
    );

    /*********************************************/
    /* Merchandise Edit Page End                 */
    /*********************************************/

    /*********************************************/
    /* Merchandise Delivery / Collection Section */
    /*********************************************/

    //find any address select boxes which need to be pre-set
    $(".billing_delivery_address_select.has_address_id").each(function () {
      $(this).val("delivery_" + $(this).data("address_id"));
    });

    $(".billing_delivery_address_select.has_collection_id").each(function () {
      $(this).val("collection_" + $(this).data("collection_id"));
    });

    $("#billing_address").change(function () {
      $("#billing_user").val($(this).find(":selected").data("user_id"));
    });

    function reset_address_fields() {
      $(".billing_delivery_address_form")
        .find("input, select")
        .each(function (element) {
          $(this).val("");
        });
      $('[name*="merchandise_address"], #billing_address').dropdown("refresh");
    }

    //reveal the merchandise address form
    //move the page to it, change the merchandise_item_id
    //to match that on the button for form submission
    var addressSelect;
    var addressSelectPreviousValue;
    var address_success = false;
    var address_text;
    var address_type;

    //$('select.billing_delivery_address_select').on('focus', function(){

    $(".billing_delivery_address_select").dropdown({
      onChange: function (e) {
        var addressSelect = $(this);
        var addressSelectPreviousValue = $(this).val();

        if (
          $(this).find("option:selected").is(".new_billing_delivery_address")
        ) {
          // e = return of current value from Semantic UI dropdown object. 0 value = add new address
          var form_conatiner = $("#billing_delivery_address_container");

          $("#billing_delivery_address_container")
            .modal({
              closable: false,
              onDeny: function onDeny() {
                if (addressSelectPreviousValue == 0) {
                  addressSelect.val("");
                } else {
                  addressSelect.val(addressSelectPreviousValue);
                }
                return true;
              },
              onShow: function () {
                $(".modal_address_form").removeClass("with_data");
                reset_address_fields();
              },
            })
            .modal("show");

          if ($(this).is('select[name*="merchandise_address"]')) {
            $("#billing_delivery_address_container").removeAttr("data-billing");
            $("#billing_delivery_address_container").attr(
              "data-billing",
              "false"
            );
          } else if ($(this).attr("name") == "billing_address") {
            $("#billing_delivery_address_container").removeAttr("data-billing");
            $("#billing_delivery_address_container").attr(
              "data-billing",
              "true"
            );
          }

          if ($(this).is('[name*="merchandise_address"]')) {
            //Check if this is new delivery or new billing address
            console.log("is new delivery address");
            form_conatiner
              .find("#merchandise_address_item_title")
              .html($(this).data("merch_title")); //change the header so the user is sure which item this address is for
            form_conatiner
              .find("input#address_merchandise_item_id")
              .val($(this).data("merchandise_id")); //change the value to the button clicked
            console.log(
              form_conatiner.find("input#address_merchandise_item_id").val()
            );
            form_conatiner
              .find("#merchandise_address_header")
              .show()
              .insertBefore($("#billing_address_header"));
            form_conatiner.find("#billing_address_header").hide();
            form_conatiner.find("#billing_address_id").val(0);
          } else {
            form_conatiner
              .find("#billing_address_header")
              .show()
              .insertBefore($("#merchandise_address_header"));
            form_conatiner.find("#merchandise_address_header").hide();
            form_conatiner.find("#billing_address_id").val(1);
          }
        }
      },
      onShow: function () {
        $(this)
          .find('[value="0"]')
          .html(
            '<div class="ui centered center aligned blue left labeled icon button"><i class="home icon"></i>Enter new address</div>'
          )
          .appendTo($(this));
        if ($(this).find('[value*="delivery"]').length) {
          $(
            '<h4 class="ui mini header" style="font-size: 0.9em; margin: 0.5rem 0;">- OR -</h4>'
          ).prependTo($(this).find('[value="0"]'));
        }

        if (!$(this).parent().hasClass("billing_address")) {
          if ($(this).find('option[value*="delivery"]').length) {
            var first_del = $(this).find('option[value*="delivery"]:first');
            if ($(this).find(".del-header").length == 0) {
              $(
                '<option disabled="true" class="del-header"></option>'
              ).insertBefore($(first_del));
              $(this)
                .find(".del-header")
                .html("<h4>-- Delivery Addresses --</h4>");
            }
          }
          if ($(this).find('option[value*="collection"]').length) {
            var first_col = $(this).find('option[value*="collection"]:first');
            if ($(this).find(".col-header").length == 0) {
              $(
                '<option disabled="true" class="col-header"></option>'
              ).insertBefore($(first_col));
              $(this)
                .find(".col-header")
                .html("<h4>-- Collection Options --</h4>");
            }
            $(this)
              .find("option.new_billing_delivery_address")
              .insertBefore($(this).find(".col-header"));
          }
        }
      },
      onHide: function () {
        if (
          $(this).find("option.new_billing_delivery_address").is(":selected") &&
          address_success == false
        ) {
          $(this).dropdown("restore defaults");
        }
      },
      preserveHTML: false,
      allowReselection: true,
    });

    //cancel address entry and reset and hide dynamic bits
    $("#cancel_address_form").click(function (e) {
      e.preventDefault();
      $("#billing_delivery_address_container").modal("hide");
      $("#merchandise_address_header").hide();
      $("#billing_address_header").hide();
      $("#address_merchandise_item_id").val(""); //change the value to the button clicked
    });

    var selected;
    var item_id;

    //submit the address form via ajax to avoid the page reload
    $(".billing_delivery_address_form").on("submit", function (e) {
      e.preventDefault();
      $.ajax({
        url: $(this).attr("action"),
        dataType: "json",
        method: "POST",
        data: "_token=" + csrftoken + "&" + $(this).serialize(),
        success: function (content) {
          //check thre was no error message
          if (content.success) {
            selected = "delivery_" + content.address_id;
            item_id = content.merchandise_id;
            $('#billing_address, optgroup[label="Delivery Addresses"]').append(
              "<option value=delivery_" +
                content.address_id +
                ">" +
                content.full_address +
                "</option>"
            );
            if (
              content.address_type == "merchandise" ||
              $("#billing_delivery_address_container").attr("data-billing") ==
                "false"
            ) {
              var merch_id = content.merchandise_id;
              $(
                "#merchandise_address_" +
                  merch_id +
                  ' optgroup[label="Delivery Addresses"] option[value="delivery_' +
                  content.address_id +
                  '"]'
              ).prop("selected", true);
              $("#merchandise_address_" + merch_id).val(
                "delivery_" + content.address_id
              );
            } else if (
              content.address_type == "billing" ||
              $("#billing_delivery_address_container").attr("data-billing") ==
                "true"
            ) {
              $(
                '#billing_address option[value="delivery_' +
                  content.address_id +
                  '"]'
              ).prop("selected", true);

              $("#billing_address").val("delivery_" + content.address_id);
              $(
                '#billing_address option[value="delivery_' +
                  content.address_id +
                  '"]'
              ).prop("selected", true);
              $("#billing_user").val(content.billing_user);
            } else {
              console.log("Dropdown to update unknown");
            }

            //now lets clear and hide the address form again
            reset_address_fields();
            $("#billing_delivery_address_container[data-billing]").removeAttr(
              "data-billing"
            );
            $("#billing_delivery_address_container[data-merch_id]").removeAttr(
              "data-merch_id"
            );
            //all done lets hide the address form again
            $("#merchandise_address_item_title").html("");
            $("#billing_delivery_address_container").modal("hide");
            $("#billing_address_header").hide();
            $("#merchandise_address_header").hide();
          } else {
            console.log("New Address Failure\n" + content.error);
          }
        },
        error: function (content) {
          console.log("New Address Failure2\n" + content.error);
        },
        complete: function () {
          if ($("#merchandise_address_" + item_id).length) {
            $("#merchandise_address_" + item_id).dropdown("refresh");
            setTimeout(function () {
              $("#merchandise_address_" + item_id).dropdown(
                "set selected",
                selected
              );
            }, 1);
          } else if (
            $('#billing_address > option[value="' + selected + '"]').length
          ) {
            $("#billing_address").dropdown("refresh");
            setTimeout(function () {
              $("#billing_address").dropdown("set selected", selected);
            }, 1);
          }
          console.log("Address added");
        },
      });
    });

    //Limit collection options if linked to a matching race entry
    $('select[name*="linkable_entry"]').on("change", function (e) {
      var reselect = false; //do we need to reselect at the end of this process

      //get merch item id
      var merchandise_item_id = $(this).parent()["context"].dataset.merch_item;
      //console.log(merchandise_item_id);

      //Have they selected the place holder, no entry or manual option
      if (
        e.currentTarget.value == "" ||
        e.currentTarget.value == 0 ||
        e.currentTarget.value == "X"
      ) {
        console.log(merchandise_item_id);
        //enable all collection options again
        $('select[name="merchandise_address[' + merchandise_item_id + ']"]')
          .find('option[value^="collection_"]')
          .each(function () {
            console.log($(this));
            $(this).prop("disabled", false);
          });
        $(
          'select[name="merchandise_address[' + merchandise_item_id + ']"]'
        ).dropdown("refresh");
      } else {
        //get chosen race_id from selected option element
        var race_id = $(this).find("option:selected").data("race_id"); //get the chosen race_id

        //loop through all the collection options for this item and disable any which aren't the matching race, if the matching race exists as an option
        if (
          $(
            'select[name="merchandise_address[' +
              merchandise_item_id +
              ']"] option[value="collection_' +
              race_id +
              '"]'
          ).length
        ) {
          $(
            'select[name="merchandise_address[' +
              merchandise_item_id +
              ']"] option[value^="collection_"]'
          ).each(function () {
            console.log($(this));
            //does this collection option the same as the race linked to
            if ($(this).val() != "collection_" + race_id) {
              if ($(this).is(":selected")) {
                reselect = true; //a incompatiable option was already selected so we need to change this
              }
              $(this).prop("disabled", true); //disable this choice
            } else {
              $(this).prop("disabled", false); //this is the same race ensure its enabled
            }
          });
          //change the option to the matching race
          if (reselect) {
            $(
              'select[name="merchandise_address[' + merchandise_item_id + ']"]'
            ).val("collection_" + race_id);
          }
        }
      }
    });

    $('select[name*="linkable_entry"]').on("change", function (e) {
      //console.log(e.currentTarget.value);

      //if manual specify chose, show the inputs for it
      if (e.currentTarget.value == "X") {
        //show the manual entry bits, ensure the values are blanked on show and enable them
        $("#linkable_manual_" + $(this).data("merch_item")).show();
        $("#linkable_manual_" + $(this).data("merch_item"))
          .find("input, select")
          .val("")
          .prop("disabled", false);
        $("#linkable_manual_" + $(this).data("merch_item"))
          .find(".linkable_race_select")
          .removeClass("disabled");
      } else {
        //else ensure all the manual inputs are blanked and hidden
        //hide the manual entry bits, ensure the values are blanked on show and disable them
        $("#linkable_manual_" + $(this).data("merch_item")).hide();
        $("#linkable_manual_" + $(this).data("merch_item"))
          .find("input, select")
          .val("")
          .prop("disabled", true);
        $("#linkable_manual_" + $(this).data("merch_item"))
          .find(".linkable_race_select")
          .addClass("disabled");
      }
    });

    //if they select the race not listed option on the manual, change the entyr select back to no link option
    $('[name*="linkable_race"]').on("change", function (e) {
      if (e.currentTarget.value == "X") {
        $('[name="linkable_entry[' + $(this).data("merch_item") + ']"]')
          .val(0)
          .trigger("change");
      }
    });

    //update some styling on the semantic dropdowns to make some options stand out
    $('div.linkable_select div.item[data-value="X"]').each(function () {
      $(this).css("color", "red");
    });

    /*************************************************/
    /* End Merchandise Delivery / Collection Section */
    /*************************************************/
  });

  $(".basket-contents, .basket-contents table, .make-refund").css(
    "max-width",
    $(window).width() - 60 + "px"
  );

  $(window).resize(function () {
    $(".basket-contents, .basket-contents table, .make-refund").css(
      "max-width",
      $(window).width() - 60 + "px"
    );
  });

  // Orders and Reports tables basket info / sub item expanding
  $(".basket-expand-ajax").on("click", function (e) {
    if ($(this).data("loaded") == 0) {
      $.get($(this).data("url")).done(function (data) {
        $(".basket-info-" + basket_id)
          .find(".basket-contents")
          .html(data);
        //reloadTrigger();
        document
          .getElementById("basket-expand" + basket_id)
          .setAttribute("data-loaded", 1);
        $(".basket-contents, .basket-contents table, .make-refund").css(
          "max-width",
          $(window).width() - 80 + "px"
        );
      });
    }

    var basket_id = $(this).data("basket");

    $(".basket-info-" + basket_id)
      .fadeToggle("fast")
      .find("td")
      .css("border-top", "none");
    $(this).find(".dropdown.icon").toggleClass("rotated");
    $(this).toggleClass("active");

    if ($(this).hasClass("active")) {
      $(".basket-info-" + basket_id)
        .find(".basket-contents")
        .fadeIn("fast");
    } else {
      $(".basket-info-" + basket_id)
        .find(".basket-contents")
        .fadeOut("fast");
    }

    $(this).find("td").css("border-bottom", "none");
  });

  $(".basket-expand").on("click", function (e) {
    var basket_id = $(this).data("basket");

    $(".basket-info-" + basket_id)
      .fadeToggle("fast")
      .find("td")
      .css("border-top", "none");
    $(this).find(".dropdown.icon").toggleClass("rotated");
    $(this).toggleClass("active");

    if ($(this).hasClass("active")) {
      $(".basket-info-" + basket_id)
        .find(".basket-contents")
        .fadeIn("fast");
    } else {
      $(".basket-info-" + basket_id)
        .find(".basket-contents")
        .fadeOut("fast");
    }

    $(this).find("td").css("border-bottom", "none");
  });

  $(".donation-expand").on("click", function (e) {
    $(this).next("tr").toggle();
  });

  // Mobile header buttons, menu and event selector

  $("#mob-header-menu").click(function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(".mobile-buttons").slideUp(300, "swing");
    } else {
      $(this).addClass("active");
      $(".mobile-buttons").slideDown(300, "swing");
    }
  });

  // Optional fields display when toggle is :checked

  $(".show-optional").each(function () {
    $(this).on("change", function () {
      if ($(this).is(":checked")) {
        $(this).parent().parent().find(".optional-val").fadeIn(300);
      } else {
        $(this).parent().parent().find(".optional-val").fadeOut(300);
      }
    });
  });

  // Forms

  // Add stlying to field errors

  $("small.error.inline")
    .addClass("ui pointing red basic label")
    .removeClass("inline");

  // Alert & warning banenrs

  var bannerHeight = $(".message-banner").height();
  var headerHeight = $(".public-header").height() - 5;

  var $delay;
  if ($(".message-banner").hasClass("error-banner")) {
    $delay = 500000;
  } else {
    $delay = 8000;
  }

  $.notifyBar({
    jqObject: $(".message-banner"),
    //cssClass: "negative",
    close: false,
    closeOnClick: true,
    delay: $delay,
    onShow: function () {
      //$('.public-header').css({'position': 'static', 'margin-top': (bannerHeight + headerHeight)});
      //$('.message-banner').css('top', '0');
      if ($(window).width() > 768) {
        //$('.public main.public-container').css({'tansition':'all linear 0.1s', 'padding-top': '2rem' });
      } else {
        //$('.public main.public-container').css({'tansition':'all linear 0.1s'});
      }
    },
    onBeforeHide: function () {
      //$('.public-header').css({'position': 'fixed'}).animate({'marginTop': '0px'}, 200);
    },
    onHide: function () {
      if ($(window).width() > 768) {
        //$('.public main.public-container').css({'tansition':'all linear 0.1s', 'padding-top': '5rem'});
      } else {
        //$('.public main.public-container').css({'tansition':'all linear 0.1s'});
      }
    },
    animationSpeed: "fast",
  });

  // Mobile Menu button etc

  $(".mobile-sidebar aside").addClass("mobile-aside");

  $("#mobile-menu-btn").on("click", function () {
    $(".ui.sidebar.mobile-sidebar")
      .sidebar("setting", "transition", "overlay")
      .sidebar("setting", { dimPage: false })
      .sidebar("toggle");
  });

  // Sidebar
  $(".ui.sidebar.mobile-sidebar").sidebar({
    onVisible: function () {
      $(
        ".mobile-sidebar aside.ui.inverted.vertical.menu.main-menu, .mobile-sidebar aside.ui.inverted.vertical.menu.main-menu > nav"
      ).show();
    },
    onHide: function () {
      $(
        ".mobile-sidebar aside.ui.inverted.vertical.menu.main-menu, .mobile-sidebar aside.ui.inverted.vertical.menu.main-menu > nav"
      ).hide();
    },
  });

  $(window).resize(function () {
    //is this owrking?

    if ($(window).width() == $(this).width()) {
      return;
    }

    //$(this).width() = $(window).width();

    if ($(this).width() > 868) {
      $("#main-menu").show(200);
    }

    if ($(this).width() > 1220) {
      $(".mobile-buttons").show(200);
    }

    if ($(this).width() <= 868) {
      $(".mobile-buttons").hide(200);
    }
  });

  // Top Event Content Menu Active Classes

  $(
    ".event-content .content-nav, .event-contact .social-nav, .event-theme .theme-nav, .event-images .images-nav, .event-files .files-nav, .event-fees .fees-nav"
  )
    .parent()
    .addClass("active");

  // Top Event Details Menu Active Classes

  $(
    ".event-summary .sum-nav, .event-address .address-nav, .aditional-options .addop-nav, .spon-options .sponop-nav, .advanced-options .advancedop-nav, .notification-options .notifyop-nav, .fees-view .fees-nav, .sa-options .saop-nav, .race-charities .charity-nav, .mailchimp-page .mailchimp-nav, .fees-view .event-fees-nav"
  )
    .parent()
    .addClass("active");

  // Top Fundraising Menu

  $(
    ".race-charities .chrity-nav, .spon-options .spon-nav, .offline-dons .offline-nav"
  ).addClass("active");

  // Top Waiting List menu

  $(
    ".waiting-list .settings-nav:not(.waiting-nav .settings-nav), .waiting-list-entries .wait-entries-nav:not(.waiting-nav .wait-entries-nav)"
  )
    .parent()
    .addClass("active");

  // Top Number Pool menu

  $(
    ".edit-number-pool:not(.action-edit-view) .new-pool-nav, .number-pools-page .edit-pools-nav, .action-edit-view .edit-pools-nav"
  )
    .parent()
    .addClass("active");

  // Left Side Menu Bar Active Classes for sub items

  var activeItem = $(".top-menu .item.active a");

  //alert(activeItem.attr('class'));

  $("#main-menu .accordion .menu .item a").each(function () {
    var navItem = $(this).attr("class");

    //alert(navItem);

    if (activeItem.hasClass(navItem)) {
      $(this).addClass("rn-orange");
      $(this).parent().removeClass("active");
    }
  });

  // Entries Page menu fix

  $(".more-items .menu .item").click(function () {
    $(this)
      .parents()
      .find(".extra-tabs .item:not(.more-items)")
      .removeClass("active");
  });

  // Create Teams

  $("#captain_flipper").on("change", function () {
    var flip = $(this).prop("checked");
    if (flip) {
      $("#non_fixed_options").hide();
    } else {
      $("#non_fixed_options").show();
    }
  });
  $("#flex_flipper").on("change", function () {
    var flip = $(this).prop("checked");
    if (flip) {
      $("#flex_options").show();
    } else {
      $("#flex_options").hide();
    }
  });
  $("#flex_count_flipper").on("change", function () {
    var flip = $(this).prop("checked");
    var div = $("#flex_count_div");
    if (flip) {
      div.hide();
    } else {
      div.show();
    }
  });
  //For the add remove many to one control

  $(".addition_holder .additem").on("click", function (e) {
    e.preventDefault();
    var top = $(this).closest(".addition_holder");
    var pick = top.find(".option");

    var inp_name = top.data("name");
    var clear = top.data("clear");

    if (pick.length > 0) {
      var pick_value = pick.val();
      var pick_value = pick_value.replace("'", "\\'");

      var pick_text = pick.find("option[value='" + pick_value + "']").text();
      var pick_text = pick_text.replace("\\", "");
      if (pick_text == "") {
        pick_text = pick.val();
      }
      if (pick_value.length > 0) {
        var res = top.find(".choices");
        var rh = res.html();
        res.html(
          rh +
            "<li>" +
            pick_text +
            '<input style="display:none" class="form_option" name="' +
            inp_name +
            '[]" value="' +
            pick_value +
            '" /></input> ' +
            '<i class="minus icon" data-question="Are you sure you want to delete this option?"></i></li>'
        );
      }
    }
    if (clear == true) {
      pick.val("");
    }
    pick.focus();
  });

  // Catch for ajax / newly added items not in DOM on initial page load

  $(document).on("click", ".addition_holder .additem", function (e) {
    e.preventDefault();
    var top = $(this).closest(".addition_holder");
    var pick = top.find(".option");

    var inp_name = top.data("name");
    var clear = top.data("clear");

    if (pick.length > 0) {
      var pick_value = pick.val();
      var pick_value = pick_value.replace("'", "\\'");

      var pick_text = pick.find("option[value='" + pick_value + "']").text();
      var pick_text = pick_text.replace("\\", "");
      if (pick_text == "") {
        pick_text = pick.val();
      }
      if (pick_value.length > 0) {
        var res = top.find(".choices");
        var rh = res.html();
        res.html(
          rh +
            "<li>" +
            pick_text +
            '<input style="display:none" class="form_option" name="' +
            inp_name +
            '[]" value="' +
            pick_value +
            '" /></input> ' +
            '<i class="minus icon" data-question="Are you sure you want to delete this option?"></i></li>'
        );
      }
    }
    if (clear == true) {
      pick.val("");
    }
    pick.focus();
  });

  // Same as above but for team structure create / edit page
  $(".addition_holder .addanitem").click(function (e) {
    e.preventDefault();
    var top = $(this).closest(".addition_holder");
    var pick = top.find(".option");

    var inp_name = top.data("name");
    var clear = top.data("clear");

    if (pick.length > 0) {
      var pick_value = pick.val();
      var pick_value = pick_value.replace("'", "\\'");

      var pick_text = pick.find("option[value='" + pick_value + "']").text();
      var pick_text = pick_text.replace("\\", "");
      if (pick_text == "") {
        pick_text = pick.val();
      }
      if (pick_value.length > 0) {
        var res = top.find(".choices");
        var rh = res.html();
        res.html(
          rh +
            '<li class="team-structure-item"><span class="slot-text"><i class="user icon rn-dark"></i>&nbsp;&nbsp;' +
            pick_text +
            '<i class="white remove circle icon remove-place" title="Remove this slot"></i></span><input style="display:none" class="form_option" name="' +
            inp_name +
            '[]" value="' +
            pick_value +
            '"></input> ' +
            "</li>"
        );
      }
    }
    if (clear == true) {
      pick.val("");
    }
    pick.focus();
  });

  // minus controls for removing choices and custom logic
  $(document).on("click", ".remove-place", function (e) {
    $(this).closest("li").remove();
  });

  $("[name=groups]").change(function () {
    var new_find = $(this).val();
    if (new_find == "join_group") {
      $("#join_group").show();
      $("#create_group").hide();
    } else if (new_find == "create_group") {
      $("#join_group").hide();
      $("#create_group").show();
    } else {
      $("#join_group").hide();
      $("#create_group").hide();
    }
  });

  $("#join_group select").keyup(function () {
    var cur_put = $(this);
    var aria = cur_put.attr("aria-invalid");

    var val = cur_put.val();
    var cldiv = $(this).closest("div");
    var resultsbox = cldiv.find(".rnJq-search-results");
    cldiv.find(".rnJq-search-fields").hide();
    var fields_box = cldiv.find(".rnJq-search-fields");
    var cur_max = 30;

    fields_box.hide();
    $(this).prop("data-chosen", false);

    fields_box.find("input").each(function () {
      $(this).val("");
    });

    resultsbox.html("");
    if (val.length > 0) {
      var row_has = 0;
      var new_val = RegExp.escape(val);
      var val_regex = new RegExp(new_val, "i");
      for (var match_row in window.group_data) {
        var group_data = window.field_data;
        var to_match = group_data[match_row].match;
        if (val_regex.test(to_match)) {
          var tempr = resultsbox.html();
          var liadd =
            '<div style="cursor:pointer; padding: 5px; background-color:#d9d9d9; border-top: white solid;" class="match_maker" data-id="$this->id"';
          liadd +=
            'data-index="' +
            match_row +
            '" data-text="' +
            group_data[match_row].match +
            '">' +
            group_data[match_row].match +
            "</div>";
          resultsbox.html(tempr + liadd);
          row_has++;
        }
        if (row_has > cur_max) {
          break;
        }
      }
    }
  });

  //Set this span to whatever the current chosen charity is
  $(".chosenCharity").text($("#chosen_charity option:selected").text());
  if (
    $("#chosen_charity option:selected").val() == "" ||
    $("#chosen_charity option:selected").val() == "none"
  ) {
    $(".chosenCharity").text("my chosen charity");
    $(".charity-terms-conditions").hide();
  }
  //On Load setup of the nudge and giftaid/LSDS stuff
  //decide if we are displaying the donation nudge or not
  //Is this charity nudge enabled?
  if (
    $("#chosen_charity option:selected").data("nudge") ||
    $("#chosen_charity").data("nudge")
  ) {
    console.log("onLoad Nudge checker");
    //yes so show and enable the dropdown
    $(".donation-nudge").show();
    $(".sg-nudge-block").addClass("persist");
    $('select[name="donation_nudge"]').attr("disabled", false);
    //Is custom selected, if so show the custom input
    if (
      $('select[name="donation_nudge"]').find(":selected").val() == "custom"
    ) {
      $(".donation-nudge-custom").fadeIn();
    } else {
      $(".donation-nudge-custom").fadeOut();
    }

    //Check if chosen charity is GiftAid enabled
    if (
      $("#chosen_charity option:selected").data("giftaid") ||
      $("#chosen_charity").data("giftaid")
    ) {
      //yes so show and enable the dropdown
      $(".donation-nudge-giftaid").fadeIn();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", false);
      //hide and disable LSDS stuff
      $(".donation-nudge-lsds").fadeOut();
      $('input[name="donation_nudge_lsds"]').attr("disabled", true);
    } else if (
      $("#chosen_charity option:selected").data("lsds") ||
      $("#chosen_charity").data("lsds")
    ) {
      //show and enable LSDS stuff

      //must be £50 to be eligable && entrant's address country must be Jersey
      if (
        ($('select[name="donation_nudge"]').val() == "50" ||
          ($('select[name="donation_nudge"]').val() == "custom" &&
            $('select[name="donation_nudge"]').val() >= 50)) &&
        $('[name="iso_country_code"]').val() == "832"
      ) {
        $(".donation-nudge-lsds").fadeIn();
        $('input[name="donation_nudge_lsds"]').attr("disabled", false);
        if ($('input[name="donation_nudge_lsds_tin"]').val()) {
          $(".lsds-tin-container").fadeIn();
        } else {
          $(".lsds-tin-container").fadeOut();
        }
      } else {
        $(".donation-nudge-lsds").fadeOut();
        $('input[name="donation_nudge_lsds"]').attr("disabled", true);
      }
      //hide and disable GiftAid stuff
      $(".donation-nudge-giftaid").fadeOut();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
    } else {
      //no so hide and disable the dropdown
      $(".donation-nudge-giftaid").fadeOut();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
      $(".donation-nudge-lsds").fadeOut();
      $('input[name="donation_nudge_lsds"]').attr("disabled", true);
    }

    //check fundraiser and display field based on it
    if (
      $("#chosen_charity option:selected").data("provider") == "sg" ||
      $("#chosen_charity").data("provider") == "SG"
    ) {
      //yes so show and enable the dropdown
      $("#provider-txt-sg").show();
      $("#fundraising-checkbox").show();
      $("#provider-txt-other").hide();
    } else if (
      $("#chosen_charity option:selected").data("provider") != "none" ||
      $("#chosen_charity").data("provider") == "none"
    ) {
      //no so hide and disable the dropdown
      $("#provider-txt-other").show();
      $("#fundraising-checkbox").hide();
      $("#provider-txt-sg").hide();
    } else {
      //nothing selected so hide them all
      $("#provider-txt-other").hide();
      $("#fundraising-checkbox").hide();
      $("#provider-txt-sg").hide();
    }
  } else {
    $delay = 8000;
  }

  $.notifyBar({
    jqObject: $(".message-banner"),
    //cssClass: "negative",
    close: false,
    closeOnClick: true,
    delay: $delay,
    onShow: function () {
      //$('.public-header').css({'position': 'static', 'margin-top': (bannerHeight + headerHeight)});
      //$('.message-banner').css('top', '0');
      if ($(window).width() > 768) {
        //$('.public main.public-container').css({'tansition':'all linear 0.1s', 'padding-top': '2rem' });
      } else {
        //$('.public main.public-container').css({'tansition':'all linear 0.1s'});
      }
    },
    onBeforeHide: function () {
      //$('.public-header').css({'position': 'fixed'}).animate({'marginTop': '0px'}, 200);
    },
    onHide: function () {
      if ($(window).width() > 768) {
        //$('.public main.public-container').css({'tansition':'all linear 0.1s', 'padding-top': '5rem'});
      } else {
        //$('.public main.public-container').css({'tansition':'all linear 0.1s'});
      }
    },
    animationSpeed: "fast",
  });

  // Mobile Menu button etc

  $(".mobile-sidebar aside").addClass("mobile-aside");

  $("#mobile-menu-btn").on("click", function () {
    $(".ui.sidebar.mobile-sidebar")
      .sidebar("setting", "transition", "overlay")
      .sidebar("setting", { dimPage: false })
      .sidebar("toggle");
  });

  // Sidebar
  $(".ui.sidebar.mobile-sidebar").sidebar({
    onVisible: function () {
      $(
        ".mobile-sidebar aside.ui.inverted.vertical.menu.main-menu, .mobile-sidebar aside.ui.inverted.vertical.menu.main-menu > nav"
      ).show();
    },
    onHide: function () {
      $(
        ".mobile-sidebar aside.ui.inverted.vertical.menu.main-menu, .mobile-sidebar aside.ui.inverted.vertical.menu.main-menu > nav"
      ).hide();
    },
  });

  $(window).resize(function () {
    //is this owrking?

    if ($(window).width() == $(this).width()) {
      return;
    }

    //$(this).width() = $(window).width();

    if ($(this).width() > 868) {
      $("#main-menu").show(200);
    }

    if ($(this).width() > 1220) {
      $(".mobile-buttons").show(200);
    }

    if ($(this).width() <= 868) {
      $(".mobile-buttons").hide(200);
    }
  });

  // Top Event Content Menu Active Classes

  $(
    ".event-content .content-nav, .event-contact .social-nav, .event-theme .theme-nav, .event-images .images-nav, .event-files .files-nav, .event-fees .fees-nav"
  )
    .parent()
    .addClass("active");

  // Top Event Details Menu Active Classes

  $(
    ".event-summary .sum-nav, .event-address .address-nav, .aditional-options .addop-nav, .spon-options .sponop-nav, .advanced-options .advancedop-nav, .notification-options .notifyop-nav, .fees-view .fees-nav, .sa-options .saop-nav, .race-charities .charity-nav, .mailchimp-page .mailchimp-nav, .fees-view .event-fees-nav .publish-nav"
  )
    .parent()
    .addClass("active");

  // Top Fundraising Menu

  $(
    ".race-charities .chrity-nav, .spon-options .spon-nav, .offline-dons .offline-nav"
  ).addClass("active");

  // Top Waiting List menu

  $(
    ".waiting-list .settings-nav:not(.waiting-nav .settings-nav), .waiting-list-entries .wait-entries-nav:not(.waiting-nav .wait-entries-nav)"
  )
    .parent()
    .addClass("active");

  // Top Number Pool menu

  $(
    ".edit-number-pool:not(.action-edit-view) .new-pool-nav, .number-pools-page .edit-pools-nav, .action-edit-view .edit-pools-nav"
  )
    .parent()
    .addClass("active");

  // Left Side Menu Bar Active Classes for sub items

  var activeItem = $(".top-menu .item.active a");

  //alert(activeItem.attr('class'));

  $("#main-menu .accordion .menu .item a").each(function () {
    var navItem = $(this).attr("class");

    //alert(navItem);

    if (activeItem.hasClass(navItem)) {
      $(this).addClass("rn-orange");
      $(this).parent().removeClass("active");
    }
  });

  // Entries Page menu fix

  $(".more-items .menu .item").click(function () {
    $(this)
      .parents()
      .find(".extra-tabs .item:not(.more-items)")
      .removeClass("active");
  });

  // Create Teams

  $("#captain_flipper").on("change", function () {
    var flip = $(this).prop("checked");
    if (flip) {
      $("#non_fixed_options").hide();
    } else {
      $("#non_fixed_options").show();
    }
  });
  $("#flex_flipper").on("change", function () {
    var flip = $(this).prop("checked");
    if (flip) {
      $("#flex_options").show();
    } else {
      $("#flex_options").hide();
    }
  });
  $("#flex_count_flipper").on("change", function () {
    var flip = $(this).prop("checked");
    var div = $("#flex_count_div");
    if (flip) {
      div.hide();
    } else {
      div.show();
    }
  });
  //For the add remove many to one control

  $(".addition_holder .additem").on("click", function (e) {
    e.preventDefault();
    var top = $(this).closest(".addition_holder");
    var pick = top.find(".option");

    var inp_name = top.data("name");
    var clear = top.data("clear");

    if (pick.length > 0) {
      var pick_value = pick.val();
      var pick_value = pick_value.replace("'", "\\'");

      var pick_text = pick.find("option[value='" + pick_value + "']").text();
      var pick_text = pick_text.replace("\\", "");
      if (pick_text == "") {
        pick_text = pick.val();
      }
      if (pick_value.length > 0) {
        var res = top.find(".choices");
        var rh = res.html();
        res.html(
          rh +
            "<li>" +
            pick_text +
            '<input style="display:none" class="form_option" name="' +
            inp_name +
            '[]" value="' +
            pick_value +
            '" /></input> ' +
            '<i class="minus icon" data-question="Are you sure you want to delete this option?"></i></li>'
        );
      }
    }
    if (clear == true) {
      pick.val("");
    }
    pick.focus();
  });

  // Catch for ajax / newly added items not in DOM on initial page load

  $(document).on("click", ".addition_holder .additem", function (e) {
    e.preventDefault();
    var top = $(this).closest(".addition_holder");
    var pick = top.find(".option");

    var inp_name = top.data("name");
    var clear = top.data("clear");

    if (pick.length > 0) {
      var pick_value = pick.val();
      var pick_value = pick_value.replace("'", "\\'");

      var pick_text = pick.find("option[value='" + pick_value + "']").text();
      var pick_text = pick_text.replace("\\", "");
      if (pick_text == "") {
        pick_text = pick.val();
      }
      if (pick_value.length > 0) {
        var res = top.find(".choices");
        var rh = res.html();
        res.html(
          rh +
            "<li>" +
            pick_text +
            '<input style="display:none" class="form_option" name="' +
            inp_name +
            '[]" value="' +
            pick_value +
            '" /></input> ' +
            '<i class="minus icon" data-question="Are you sure you want to delete this option?"></i></li>'
        );
      }
    }
    if (clear == true) {
      pick.val("");
    }
    pick.focus();
  });

  // Same as above but for team structure create / edit page
  $(".addition_holder .addanitem").click(function (e) {
    e.preventDefault();
    var top = $(this).closest(".addition_holder");
    var pick = top.find(".option");

    var inp_name = top.data("name");
    var clear = top.data("clear");

    if (pick.length > 0) {
      var pick_value = pick.val();
      var pick_value = pick_value.replace("'", "\\'");

      var pick_text = pick.find("option[value='" + pick_value + "']").text();
      var pick_text = pick_text.replace("\\", "");
      if (pick_text == "") {
        pick_text = pick.val();
      }
      if (pick_value.length > 0) {
        var res = top.find(".choices");
        var rh = res.html();
        res.html(
          rh +
            '<li class="team-structure-item"><span class="slot-text"><i class="user icon rn-dark"></i>&nbsp;&nbsp;' +
            pick_text +
            '<i class="white remove circle icon remove-place" title="Remove this slot"></i></span><input style="display:none" class="form_option" name="' +
            inp_name +
            '[]" value="' +
            pick_value +
            '"></input> ' +
            "</li>"
        );
      }
    }
    if (clear == true) {
      pick.val("");
    }
    pick.focus();
  });

  // minus controls for removing choices and custom logic
  $(document).on("click", ".remove-place", function (e) {
    $(this).closest("li").remove();
  });

  $("[name=groups]").change(function () {
    var new_find = $(this).val();
    if (new_find == "join_group") {
      $("#join_group").show();
      $("#create_group").hide();
    } else if (new_find == "create_group") {
      $("#join_group").hide();
      $("#create_group").show();
    } else {
      $("#join_group").hide();
      $("#create_group").hide();
    }
  });

  $("#join_group select").keyup(function () {
    var cur_put = $(this);
    var aria = cur_put.attr("aria-invalid");

    var val = cur_put.val();
    var cldiv = $(this).closest("div");
    var resultsbox = cldiv.find(".rnJq-search-results");
    cldiv.find(".rnJq-search-fields").hide();
    var fields_box = cldiv.find(".rnJq-search-fields");
    var cur_max = 30;

    fields_box.hide();
    $(this).prop("data-chosen", false);

    fields_box.find("input").each(function () {
      $(this).val("");
    });

    resultsbox.html("");
    if (val.length > 0) {
      var row_has = 0;
      var new_val = RegExp.escape(val);
      var val_regex = new RegExp(new_val, "i");
      for (var match_row in window.group_data) {
        var group_data = window.field_data;
        var to_match = group_data[match_row].match;
        if (val_regex.test(to_match)) {
          var tempr = resultsbox.html();
          var liadd =
            '<div style="cursor:pointer; padding: 5px; background-color:#d9d9d9; border-top: white solid;" class="match_maker" data-id="$this->id"';
          liadd +=
            'data-index="' +
            match_row +
            '" data-text="' +
            group_data[match_row].match +
            '">' +
            group_data[match_row].match +
            "</div>";
          resultsbox.html(tempr + liadd);
          row_has++;
        }
        if (row_has > cur_max) {
          break;
        }
      }
    }
  });

  //Set this span to whatever the current chosen charity is
  $(".chosenCharity").text($("#chosen_charity option:selected").text());
  if (
    $("#chosen_charity option:selected").val() == "" ||
    $("#chosen_charity option:selected").val() == "none"
  ) {
    $(".chosenCharity").text("my chosen charity");
    $(".charity-terms-conditions").hide();
  }
  //On Load setup of the nudge and giftaid/LSDS stuff
  //decide if we are displaying the donation nudge or not
  //Is this charity nudge enabled?
  if (
    $("#chosen_charity option:selected").data("nudge") ||
    $("#chosen_charity").data("nudge")
  ) {
    console.log("onLoad Nudge checker");
    //yes so show and enable the dropdown
    $(".donation-nudge").show();
    $(".sg-nudge-block").addClass("persist");
    $('select[name="donation_nudge"]').attr("disabled", false);
    //Is custom selected, if so show the custom input
    if (
      $('select[name="donation_nudge"]').find(":selected").val() == "custom"
    ) {
      $(".donation-nudge-custom").fadeIn();
    } else {
      $(".donation-nudge-custom").fadeOut();
    }

    //Check if chosen charity is GiftAid enabled
    if (
      $("#chosen_charity option:selected").data("giftaid") ||
      $("#chosen_charity").data("giftaid")
    ) {
      //yes so show and enable the dropdown
      $(".donation-nudge-giftaid").fadeIn();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", false);
      //hide and disable LSDS stuff
      $(".donation-nudge-lsds").fadeOut();
      $('input[name="donation_nudge_lsds"]').attr("disabled", true);
    } else if (
      $("#chosen_charity option:selected").data("lsds") ||
      $("#chosen_charity").data("lsds")
    ) {
      //show and enable LSDS stuff

      //must be £50 to be eligable && entrant's address country must be Jersey
      if (
        ($('select[name="donation_nudge"]').val() == "50" ||
          ($('select[name="donation_nudge"]').val() == "custom" &&
            $('select[name="donation_nudge"]').val() >= 50)) &&
        $('[name="iso_country_code"]').val() == "832"
      ) {
        $(".donation-nudge-lsds").fadeIn();
        $('input[name="donation_nudge_lsds"]').attr("disabled", false);
        if ($('input[name="donation_nudge_lsds_tin"]').val()) {
          $(".lsds-tin-container").fadeIn();
        } else {
          $(".lsds-tin-container").fadeOut();
        }
      } else {
        $(".donation-nudge-lsds").fadeOut();
        $('input[name="donation_nudge_lsds"]').attr("disabled", true);
      }
      //hide and disable GiftAid stuff
      $(".donation-nudge-giftaid").fadeOut();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
    } else {
      //no so hide and disable the dropdown
      $(".donation-nudge-giftaid").fadeOut();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
      $(".donation-nudge-lsds").fadeOut();
      $('input[name="donation_nudge_lsds"]').attr("disabled", true);
    }

    //check fundraiser and display field based on it
    if (
      $("#chosen_charity option:selected").data("provider") == "sg" ||
      $("#chosen_charity").data("provider") == "SG"
    ) {
      //yes so show and enable the dropdown
      $("#provider-txt-sg").show();
      $("#fundraising-checkbox").show();
      $("#provider-txt-other").hide();
    } else if (
      $("#chosen_charity option:selected").data("provider") != "none" ||
      $("#chosen_charity").data("provider") == "none"
    ) {
      //no so hide and disable the dropdown
      $("#provider-txt-other").show();
      $("#fundraising-checkbox").hide();
      $("#provider-txt-sg").hide();
    } else {
      //nothing selected so hide them all
      $("#provider-txt-other").hide();
      $("#fundraising-checkbox").hide();
      $("#provider-txt-sg").hide();
    }
  } else {
    //no so hide and disable the dropdown
    $(".donation-nudge").fadeOut();
    if ($(".donation-nudge-custom").is(":visible")) {
      $(".donation-nudge-custom").hide();
    }
    $('select[name="donation_nudge"]').attr("disabled", true);
    //hide disable giftaid bits too
    $(".donation-nudge-giftaid").fadeOut();
    $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
    //hide disable LSDS bits too
    $(".donation-nudge-lsds").fadeOut();
    $('input[name="donation_nudge_lsds"]').attr("disabled", true);
    //nothing selected so hide them all
    $("#provider-txt-other").hide();
    $("#provider-txt-sg").hide();
  }

  //check fundraiser and display field based on it
  if (
    $("#chosen_charity option:selected").data("provider") == "sg" ||
    $("#chosen_charity").data("provider") == "SG"
  ) {
    //yes so show
    $("#fundraising-checkbox").show();
  } else if (
    $("#chosen_charity option:selected").data("provider") != "none" ||
    $("#chosen_charity").data("provider") == "none"
  ) {
    //no so hide
    $("#fundraising-checkbox").hide();
  } else {
    //nothing selected so hide
    $("#fundraising-checkbox").hide();
  }

  //change the displayed charity if new one selected
  $("#chosen_charity").change(function () {
    var $provider = $(this).find("option:selected").data("provider");
    //get DOM object to change the name in
    $(".charity-terms-conditions").show();
    $(".chosenCharity").text($("#chosen_charity option:selected").text());
    if (
      $("#chosen_charity option:selected").val() == "" ||
      $("#chosen_charity option:selected").val() == "none"
    ) {
      $(".chosenCharity").text("my chosen charity");
      $(".charity-terms-conditions").hide();
    }
    console.log($provider);

    //check fundraiser and display field based on it
    if ($provider == "sg" || $provider == "SG") {
      //yes so show and enable the dropdown
      $("#provider-txt-sg").fadeIn();
      $("#fundraising-checkbox").fadeIn();
      $("#provider-txt-other").hide();
    } else if ($provider != "none" || $provider == "other") {
      //no so hide and disable the dropdown
      $("#provider-txt-other").fadeIn();
      $("#fundraising-checkbox").fadeOut();
      $("#provider-txt-sg").hide();
    } else {
      //nothing selected so hide them all
      $("#provider-txt-other").fadeOut();
      $("#fundraising-checkbox").fadeOut();
      $("#provider-txt-sg").fadeOut();
    }

    //Is this charity nudge enabled?
    if ($("#chosen_charity option:selected").data("nudge")) {
      //yes so show and enable the dropdown
      $(".donation-nudge").show();
      $('select[name="donation_nudge"]').attr("disabled", false);
    } else {
      //no so hide and disable the dropdown
      $(".donation-nudge").hide();
      //$('.sg-nudge-block.persist').removeClass('persist');
      $('select[name="donation_nudge"]').attr("disabled", true);
      //$('select[name="donation_nudge"]').val(5); //reset selection back to £5
    }

    //Check if chosen charity is GiftAid enabled
    if (
      $("#chosen_charity option:selected").data("giftaid") &&
      $("#chosen_charity option:selected").data("nudge") &&
      $('[name="iso_country_code"]').val() == "826"
    ) {
      //yes so show and enable the dropdown
      $(".donation-nudge-giftaid").show();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", false);
      //hide and disable GiftAid stuff
      $(".donation-nudge-lsds").hide();
      $('input[name="donation_nudge_lsds"]').attr("disabled", true);
    } else if (
      $("#chosen_charity option:selected").data("lsds") &&
      $("#chosen_charity option:selected").data("lsds")
    ) {
      //show and enable LSDS stuff
      //must be £50 o be eligable
      if (
        ($('select[name="donation_nudge"]').val() >= "50" ||
          ($('select[name="donation_nudge"]').val() == "custom" &&
            $('input[name="donation_nudge_custom"]').val() >= 50)) &&
        $('[name="iso_country_code"]').val() == "832"
      ) {
        $(".donation-nudge-lsds").show();
        $('input[name="donation_nudge_lsds"]').attr("disabled", false);
      } else {
        $(".donation-nudge-lsds").fadeOut();
        $('input[name="donation_nudge_lsds"]').attr("disabled", true);
      }
      //hide and disable GiftAid stuff
      $(".donation-nudge-giftaid").hide();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
    } else {
      //no so hide and disable the dropdown
      $(".donation-nudge-giftaid").hide();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
      $(".donation-nudge-lsds").hide();
      $('input[name="donation_nudge_lsds"]').attr("disabled", true);
    }

    if ($(".donation-nudge").is(":visible")) {
      $(".sg-nudge-block, .sg-logo").show().addClass("temp-shadow persist");
      setTimeout(function () {
        $(".sg-nudge-block, .sg-logo").removeClass("temp-shadow");
      }, 1500);
    } else {
      $(".donation-nudge-custom").hide();
    }
  });

  $('input[name="donation_nudge_lsds"]').change(function () {
    if (this.checked) {
      $(".lsds-tin-container").fadeIn();
      $('input[name="donation_nudge_lsds_tin"]')
        .focus()
        .addClass("focused")
        .blur(function () {
          $(this).removeClass("focused");
        });
    } else {
      $(".lsds-tin-container").fadeOut();
      if ($('input[name="donation_nudge_lsds_tin"]').val() != "") {
        $('input[name="donation_nudge_lsds_tin"]').val("");
      }
    }
  });

  $('select[name="donation_nudge"]').change(function () {
    if (
      $("#chosen_charity option:selected").data("giftaid") ||
      $("#chosen_charity").data("giftaid")
    ) {
      if ($(this).val() == "0") {
        $(".donation-nudge-giftaid").fadeOut();
        $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
      } else {
        $(".donation-nudge-giftaid").fadeIn();
        $('input[name="donation_nudge_giftaid"]').attr("disabled", false);
      }
    }
    if ($(this).val() == "custom") {
      $(".donation-nudge-custom").fadeIn();
      $(this).prop("required", false);
      $(".donation-nudge").removeClass("required");
      $('input[name="donation_nudge_custom"]')
        .prop("required", true)
        .parent()
        .addClass("required");
      //do LSDS checks here too
      if (
        $("#chosen_charity option:selected").data("lsds") &&
        $("#chosen_charity option:selected").data("lsds") &&
        $('input[name="donation_nudge_custom"]').val() >= 50 &&
        $('[name="iso_country_code"]').val() == "832"
      ) {
        $(".donation-nudge-lsds").show();
        $('input[name="donation_nudge_lsds"]').attr("disabled", false);
      } else {
        $(".donation-nudge-lsds").hide();
        $('input[name="donation_nudge_lsds"]').attr("disabled", true);
      }
    } else {
      $(".donation-nudge-custom").fadeOut();
      $('input[name="donation_nudge_custom"]').val(null);
      $(this).prop("required", true);
      $(".donation-nudge").addClass("required");
      $('input[name="donation_nudge_custom"]')
        .prop("required", false)
        .parent()
        .removeClass("required");
      //do LSDS checks here too
      if (
        $("#chosen_charity option:selected").data("lsds") &&
        $("#chosen_charity option:selected").data("lsds") &&
        $(this).val() == "50" &&
        $('[name="iso_country_code"]').val() == "832"
      ) {
        $(".donation-nudge-lsds").show();
        $('input[name="donation_nudge_lsds"]').attr("disabled", false);
      } else {
        $(".donation-nudge-lsds").hide();
        $('input[name="donation_nudge_lsds"]').attr("disabled", true);
      }
    }
  });

  //on changeing custom amount check if qualifies for LSDS
  $('input[name="donation_nudge_custom"]').on("input", function () {
    console.log("custom change triggered");
    if (
      $("#chosen_charity option:selected").data("lsds") &&
      $("#chosen_charity option:selected").data("lsds") &&
      $(this).val() >= 50 &&
      $('[name="iso_country_code"]').val() == "832"
    ) {
      $(".donation-nudge-lsds").show();
      $('input[name="donation_nudge_lsds"]').attr("disabled", false);
    } else {
      $(".donation-nudge-lsds").hide();
      $('input[name="donation_nudge_lsds"]').attr("disabled", true);
    }
  });

  // On changing country - hides / shows LSDS if other conditions as set out above are met
  $('[name="iso_country_code"]').on("change", function (e) {
    if (e.currentTarget.value != "832") {
      $(".donation-nudge-lsds").hide();
      $('input[name="donation_nudge_lsds"]').attr("disabled", true);
      if (
        e.currentTarget.value == "826" &&
        $("#chosen_charity option:selected").data("giftaid")
      ) {
        //UK, show giftaid if true
        $(".donation-nudge-giftaid").fadeIn();
        $('input[name="donation_nudge_giftaid"]').attr("disabled", false);
      } else {
        $(".donation-nudge-giftaid").fadeOut();
        $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
      }
    } else {
      if (
        $("#chosen_charity option:selected").data("lsds") &&
        $("#chosen_charity option:selected").data("lsds") &&
        ($('select[name="donation_nudge"]').val() >= 50 ||
          $('input[name="donation_nudge_custom"]').val() >= 50)
      ) {
        $(".donation-nudge-lsds").show();
        $('input[name="donation_nudge_lsds"]').attr("disabled", false);
        $(".donation-nudge-giftaid").hide();
        $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
      }
      $(".donation-nudge-giftaid").fadeOut();
      $('input[name="donation_nudge_giftaid"]').attr("disabled", true);
    }
  });

  // Clear checked value of LSDS and TIN field if LSDS is hidden as a result of changing country, donation value etc.
  $(document).on(
    "change input",
    'select[name="iso_country_code"], select[name="donation_nudge"], input[name="donation_nudge_custom"]',
    function () {
      if ($(".donation-nudge-lsds").is(":hidden")) {
        $('input[name="donation_nudge_lsds"]').prop("checked", false);
        $('input[name="donation_nudge_lsds_tin"]').val("");
      }
    }
  );
});

/*******************************************************************************
 Public JS
 *******************************************************************************/

// Check header / footer background colour lightness to decide whether the text is light or dark

$.fn.contrastColour = function () {
  return this.each(function () {
    var bg = $(this).css("background-color");

    //use first opaque parent bg if element is transparent
    if (bg == "transparent" || bg == "rgba(0, 0, 0, 0)") {
      $(this)
        .parents()
        .each(function () {
          bg = $(this).css("background-color");

          if (bg != "transparent" && bg != "rgba(0, 0, 0, 0)") return false;
        });

      //exit if all parents are transparent
      if (bg == "transparent" || bg == "rgba(0, 0, 0, 0)") return false;
    }

    //get r,g,b and decide
    var rgb = bg
      .replace(/^(rgb|rgba)\(/, "")
      .replace(/\)$/, "")
      .replace(/\s/g, "")
      .split(",");
    var yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;

    if (yiq >= 128) {
      $(this).removeClass("light-colour");
    } else {
      $(this).addClass("light-colour");
    }
  });
};

if ($(".item_count").length) {
  if (
    $("header.public-header").css("background-color") == "rgb(228, 112, 30)"
  ) {
    $(".item_count").removeClass("orange").css({
      "background-color": "rgb(32, 58, 95)",
      color: "rgb(255,255,255)",
    });
  }
}

$(function () {
  $(
    ".public-header .org-title > h4, .public-header .basket-menu, .public-header .mobile-main-menu, .public-footer .footer-content, .public-footer .footer-content a"
  ).contrastColour();
  $(".public-container .cats .cat-list .cat a").contrastColour();

  if (
    $("header.public-header, footer.public-footer").css("background-color") ==
      "rgb(228, 112, 30)" ||
    $("header.public-header, footer.public-footer").css("background-color") ==
      "rgb(237, 106, 41)"
  ) {
    $(
      ".public-header .org-title > h4, .public-header .basket-menu, .public-header .lang-selector, .public-header .mobile-main-menu, .public-footer .footer-content, .public-footer .footer-content a"
    ).addClass("light-colour");
  }
});

$(".public-accordion .content").each(function () {
  if ($(this).hasClass("active")) {
    $(this).prev().addClass("active");
    $(this).prev().find("i").addClass("rotated");
  }
});

$(".public-accordion .content").hide();
$(".public-accordion .content.active").show();

// Only add the Semantic UI stylesheet if older iOS versions (7 or below)
function iOSversion() {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
}

var ver = iOSversion();

if (Array.isArray(ver)) {
  if (ver[0] <= 7) {
    $("body.public")
      .prev("head")
      .append(
        '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.3/semantic.min.css" integrity="sha512-i4WFDVdZrL1g9Ti6rRHbJok1p9K9/3j1Xho81yrUtw5+29aXhSOd7MhF+wbQofb4SLx0MFD6aQWMpqzuLf8reA==" crossorigin="anonymous" />'
      );
  }
}

/*********************************************************************
 -- Mobile layout Adjustment --

 * Changes order of elements so that price and add to basket button
 appear at bottom of ticket info.
 * Moves basket to slide-in side bar & menu item to header
 * Adds counter to display number of items in basket
 * Moves login accordion to top of page

 *********************************************************************/

// This function is responsible for animating the mobile basket icon when the item count changes
function mobile_basket_animation(itemCount) {
  // Check if we are not on the checkout page
  if ($(".checkout-page").length == 0) {
    // Get the previous item count from local storage
    let previousCount = localStorage.getItem("itemCount");

    // Animate the item count element with a 'tada' animation, lasting 1 second
    $(".item_count").transition({
      animation: "tada",
      duration: "1s",
    });

    // If the previous item count is different from the current item count, show the basket menu popup
    if (previousCount != itemCount) {
      $(".basket-menu").popup("show");
    }

    // Set a timer to hide the basket menu popup and remove its 'data-content' attribute after 1 second
    setTimeout(function () {
      $(".basket-menu").popup("hide");
      $(".basket-menu").removeAttr("data-content");
    }, 1000);

    // Update the item count in local storage
    localStorage.setItem("itemCount", itemCount);
  }
}

function mobile_layout() {
  var width = $(window).width();
  var org_page = false;

  // Change ticket items layout for mobiles (under 580px wide)
  $("body.public .add-tickets .ticket-items").each(function (e) {
    var parent = $(this);
    if (width <= 580) {
      parent.find(".price-title").prependTo(parent.find(".buy-btn"));
      if (
        parent.find(".desc-btn").length &&
        parent.find(".breaker").length == 0
      ) {
        $('<div class="breaker"></div>').insertAfter(parent.find(".desc-btn"));
      } else if (
        parent.find(".breaker").length == 0 &&
        parent.find(".wlist").length == 0
      ) {
        $('<div class="breaker"></div>').insertAfter(parent.find("strong"));
      }
      if ($(".wlist").length > 0) {
        parent.find(".wlist").each(function (e) {
          var wlist = $(this);
          parent.find("form").insertAfter(wlist);
        });
      }
    } else {
      parent.find(".price-title").insertBefore(parent.find(".item-title"));
      parent.find(".breaker").remove();
      if ($(".wlist").length > 0) {
        parent.find(".wlist").each(function (e) {
          var wlist = $(this);
          parent.find("form").insertBefore(wlist);
        });
      }
    }
  });

  // Display basket / cart icon in header when 1 or more item has been added and show nuber of itmes
  var individual = $("main .basket .entry_head")
      .not(".team_entry")
      .not(".series_entry").length,
    team = $("main .basket .team_head").length,
    series = $("main .basket .series_head").length,
    other = $('main .basket .other_item:not([data-discount="true"])').length,
    item_count = individual + team + series + other;

  if (width <= 768) {
    if (item_count > 0) {
      // Animate basket if there was any updates (items removed or added)
      mobile_basket_animation(item_count);

      $("header.public-header .basket-menu").show();
      $(".item_count").removeClass("hidden");
      $(".item_count").text(item_count);
      $("#mobile-basket .public-container .no-items").remove();

      if ($(".mobile-cta-footer .basket-menu").length == 0) {
        $("header.public-header .basket-menu")
          .clone(true)
          .insertAfter(".mobile-cta-footer .register-btn");
        $(".mobile-cta-footer .basket-menu").addClass("btn");
      }
    } else {
      // Add text to say no items in basket
      if ($("#mobile-basket .public-container .no-items").length == 0) {
        $("#mobile-basket .public-container").append(
          '<p class="no-items"><em>' +
            (typeof $basket_empty !== "undefined" ? $basket_empty : "") +
            "</em></p>"
        );
      }
    }

    if ($(".checkout-page").length) {
      // If on the checkout pages display basket only in footer
      if ($(".mobile-cta-footer .basket-menu").length == 0) {
        $("header.public-header .basket-menu")
          .clone(true)
          .appendTo(".mobile-cta-footer");
        if (basket_pay == true) {
          // If on the delivery address view, include discount code in button text
          $(".mobile-cta-footer .basket-menu")
            .addClass("btn reg-form-button")
            .append("<span>Basket / discount code</span>");
        } else {
          $(".mobile-cta-footer .basket-menu")
            .addClass("btn reg-form-button")
            .append("<span>View basket</span>");
        }
      }
    }

    // Move the login accordion to the top of the page on mobiles
    if ($(".mobile-login-accordion").length == 0) {
      $(".chkt-accordion").each(function () {
        if ($(this).find(".chkt-login-blk").length) {
          if ($(".register-breadcrumb").length) {
            $(this)
              .addClass("mobile-login-accordion")
              .show()
              .insertAfter(".register-breadcrumb");
          } else {
            $(this)
              .addClass("mobile-login-accordion")
              .show()
              .insertAfter(".org-banner");
          }
        }
      });
    }
    $(".chkt-accordion")
      .not(
        ".mobile-login-accordion, .merchandice-container > .public-accordion"
      )
      .hide();
    $(".mobile-login-accordion").show();

    // Move basket to right side slide-in menu
    if ($("#mobile-basket").find(".basket").length == 0) {
      $(".main-bskt").clone(true).appendTo("#mobile-basket .main");
    }

    if ($("main.main").attr("style")) {
      $("main.main").removeAttr("style");
    }
  } else {
    $("header.public-header .basket-menu").hide();
    $(".item_count").addClass("hidden");
    $(".mobile-login-accordion").hide();
    $(".chkt-accordion").not(".mobile-login-accordion").show();

    if ($(".mobile-login-accordion").length) {
      $(".mobile-login-accordion").each(function () {
        if ($(this).find(".chkt-login-blk").length) {
          $(this)
            .removeClass("mobile-login-accordion")
            .show()
            .prependTo(".one-col");
        }
      });
    }
    $(".chkt-accordion")
      .not(
        ".mobile-login-accordion, .merchandice-container > .public-accordion"
      )
      .show();
    $(".mobile-login-accordion").show();
  }

  if (width <= 680) {
    if ($(".mobile-event-dates").length) {
      if ($(".mobile-event-dates .event-date").length == 0) {
        $(".event-date").clone().show().appendTo(".mobile-event-dates");
        var text = $.trim($(".mobile-event-dates .event-date").text());
        $(".mobile-event-dates .event-date").html(text.substring(2));
      }
      $(".mobile-event-dates").show();
    }
    if ($(".mobile-main-menu").is(":hidden")) {
      $(".mobile-main-menu").attr(
        "style",
        "display: inline-flex; display: -webkit-inline-flex;"
      );
    }
    if (
      $(".register-breadcrumb").length &&
      $(".mobile-nav .bread-item").length == 0
    ) {
      $(".register-breadcrumb .bread-item")
        .removeClass("inline-blk")
        .clone()
        .each(function () {
          $(this).addClass("step");
          if ($(this).find("a").length == 0) {
            $(this).addClass("active");
          } else {
            $(this).addClass("completed");
          }
        })
        .appendTo(".mobile-nav");
    }
    if ($(".lang-selector").length) {
      $(".lang-selector")
        .addClass("mobile-lang-selector")
        .insertAfter(".mobile-nav");
    }
    // If discount code field present on basket view add to mobile checkout flow on billing address & ready to pay pages if not free basket
    if ($(".basket-complete .checkout-total .total-amount").length) {
      var basket_val_int = parseFloat(
        $(".basket-complete .checkout-total .total-amount")[0].innerText.match(
          /\d+/
        )
      );
    }
    if ($(".billing-address").length && $("#btn_pay").length == 0) {
      // If on billing address stage of checkout
      if ($("#mobile_discount_code").length == 0) {
        var discount_text = $('[data-discount="true"] > .step-left')
          .eq(0)
          .text();
        var discount_code_block = $(
          // got_discount_code & discount_code_applied declared top of register.checkout.blade.php (lang files)
          '<h4 class="border-wrapped-header discount-code-header">' +
            (typeof got_discount_code != "undefined" ? got_discount_code : "") +
            "</h4>" +
            '<div class="border-wrapped" id="mobile_discount_code">' +
            ($('[data-discount="true"]').length
              ? '<div class="ui green label"><i class="ui check icon"></i> ' +
                (typeof discount_code_applied != "undefined"
                  ? discount_code_applied
                  : "") +
                ':<span class="detail">' +
                discount_text +
                "</span></div>"
              : "") +
            "</div>" +
            '<div class="spacer"></div>'
        );
        if (typeof pay_form != "undefined") {
          // declared top of register.checkout.blade.php
          if (pay_form == true) {
            discount_code_block.appendTo(".two-col").first();
            $(".discount-code-header").css("margin-top", "1rem");
          }
        } else {
          discount_code_block.prependTo(".two-col").first();
        }
        if ($("#mobile_discount_code .discount-section").length == 0) {
          $(".discount-section")
            .eq(0)
            .clone(true)
            .prependTo("#mobile_discount_code")
            .find(".spacer")
            .remove();
          $("#mobile_discount_code .discount-section").css(
            "margin-bottom",
            "1.5rem"
          );
        }
      }
    }
  } else {
    $(".mobile-main-menu").hide();
    $(".register-breadcrumb .bread-item").addClass("inline-blk");
    if ($(".mobile-event-dates").length) {
      $(".mobile-event-dates").hide();
    }
    if ($("#mobile-menu .lang-selector").length) {
      $(".lang-selector").insertBefore("header.public-header .powered-by");
    }
    if ($("#mobile_discount_code").length) {
      $("#mobile_discount_code, .discount-code-header").remove();
    }
  }

  if (width <= 359) {
    $("#mobile-basket").removeClass("wide");
  } else {
    if (!$("#mobile-basket").hasClass("wide")) {
      $("#mobile-basket").addClass("wide");
    }
  }
}

mobile_layout();

// Mobile bottom / slide-in register button + basket button

if ($(".mobile-cta-footer").length) {
  var pre_reg_notes;
  var $footer = $(".mobile-cta-footer"),
    $clone = $footer.before($footer.clone().addClass("clone"));
  var topOfPage = $(window).scrollTop(),
    regOffset = $(".reg-bottom-marker").offset().top,
    distance = regOffset - topOfPage;
  var basket_pay;
}

// Only bother showing the register button when at the top of the page when there's pre reg text content

function display_reg_btn() {
  if (pre_reg_notes == true) {
    // For iPad portrait and smaller devices

    if ($(window).width() <= 768) {
      if ($(window).width() < 420) {
        var preReg = $(".col:first-child").offset().top;
        $(window).on("scroll", function () {
          var fromTop = $(window).scrollTop();
          if ($(".mobile-cta-footer .basket-menu").length == 0) {
            $("body").toggleClass(
              "down",
              fromTop > distance || fromTop < preReg
            );
          } else {
            $("body").toggleClass("down", fromTop > topOfPage);
          }
        });
      } else {
        $(window).on("scroll", function () {
          var fromTop = $(window).scrollTop();
          if ($(".mobile-cta-footer .basket-menu").length == 0) {
            $("body").toggleClass("down", fromTop > distance);
          } else {
            $("body").toggleClass("down", fromTop > topOfPage);
          }
        });
      }
    }
  } else {
    if ($(window).width() <= 768) {
      $(window).on("scroll", function () {
        if (basket_pay != true) {
          var fromTop = $(window).scrollTop();
          if ($(".mobile-cta-footer .basket-menu").length == 0) {
            $("body").toggleClass("down", fromTop > distance);
          } else {
            $("body").toggleClass("down", fromTop > topOfPage);
          }
        }
      });

      if (basket_pay == true) {
        $("body").addClass("down");

        function basketComplete() {
          setTimeout(function () {
            if ($(".basket-complete").hasClass("visible")) {
              $(".mobile-cta-footer").css("visibility", "hidden");
              $("footer.public-footer").css("padding-bottom", "0px");
            } else if ($(".basket-complete").hasClass("hidden")) {
              $("footer.public-footer").removeAttr("style");
              $(".mobile-cta-footer").css("visibility", "visible");
            }
          }, 1);
        }

        if ($(".basket-complete").hasClass("visible")) {
          basketComplete();
        }

        $(".basket-complete")[0].addEventListener(
          "webkitAnimationEnd",
          basketComplete
        );
        $(".basket-complete")[0].addEventListener(
          "animationend",
          basketComplete
        );

        $(".edit-basket-btn").on("click", function () {
          $("footer.public-footer").removeAttr("style");
          $(".mobile-cta-footer").css("visibility", "visible");
        });

        $(window).on("scroll", function () {
          var fromTop = $(window).scrollTop();
          $("body").toggleClass("down", fromTop != distance);
        });
      }
    }
  }
}

// Mobile bottom / slide-in register button + basket button

if ($(".mobile-cta-footer").length) {
  var pre_reg_notes;
  var $footer = $(".mobile-cta-footer"),
    $clone = $footer.before($footer.clone().addClass("clone"));
  var topOfPage = $(window).scrollTop(),
    regOffset = $(".reg-bottom-marker").offset().top,
    distance = regOffset - topOfPage;

  // Only bother showing the register button when at the top of the page when there's pre reg text content
  display_reg_btn();
}

$(function () {
  $(".basket-menu").click(function (e) {
    $("#mobile-basket")
      .sidebar({
        onVisible: function onVisible() {
          $("#racecheck-widget").hide();
          if ($("#mobile-basket").is(":hidden")) {
            $("#mobile-basket").fadeIn(300);
          }
        },
        onHidden: function onHidden() {
          $("#racecheck-widget").show();
        },
      })
      .sidebar("toggle");
  });

  $(".close-basket").click(function () {
    $("#mobile-basket").sidebar("hide");
    console.log("show rc");
    $("#racecheck-widget").show();
  });

  $(".mobile-main-menu").click(function () {
    $("#mobile-menu")
      .sidebar({
        onVisible: function onVisible() {
          $("#racecheck-widget").hide();
          if ($("#mobile-menu").is(":hidden")) {
            $("#mobile-menu").fadeIn(300);
          }
        },
        onHidden: function onHidden() {
          $("#racecheck-widget").show();
        },
      })
      .sidebar("toggle");
  });

  $(".close-menu").click(function () {
    $("#mobile-menu").sidebar("hide");
  });

  // Fire mobile layout function when device changes orientation
  $(window).resize(function () {
    mobile_layout();
  });

  if ($(".public-header .org-title > h4").hasClass("light-colour")) {
    $(".powered-by img").attr(
      "src",
      "https://s3.eu-west-1.amazonaws.com/assets.race-nation.com/platform-resources/poweredby_white.png"
    );
    $(".lang-selector").addClass("light-colour");
  } else {
    $(".powered-by img").attr(
      "src",
      "https://s3.eu-west-1.amazonaws.com/assets.race-nation.com/platform-resources/poweredby_black.png"
    );
  }

  if ($(".reg-bottom-marker").length) {
    display_reg_btn();

    $(window).on("resize", function () {
      display_reg_btn();
    });
  }

  // On click event, animate scroll to regsitration area

  if ($(".register-btn").length) {
    $(".register-btn").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $("#registration").offset().top,
        },
        350
      );
    });
  }

  // Custom Accordion for displaying various events / tickets (compatible with IE8 and above)

  $(".ui.accordion:not(.category-accordion)").accordion();
  $(".public-accordion .title")
    .not(".ballot-info-title, .accordion-title")
    .click(function () {
      var $this = $(this);

      $this.addClass("active");
      $(this).find(".dropdown.icon").addClass("rotated");

      if ($this.next().hasClass("active")) {
        $this.next().removeClass("active");
        $this.next().slideUp(350);
        $this.removeClass("active");
        $this.find(".dropdown.icon").removeClass("rotated");
      } else {
        $this.parent().find("div .content").removeClass("active");
        $this.next().toggleClass("active");
        $this.next().slideToggle(350);
        $this.addClass("active");
        $this.find(".dropdown.icon").addClass("rotated");
      }
    });

  // Event info modal on organiser & category pages

  $(".event-info-btn").click(function () {
    var title = $(this).attr("data-title");
    var evDate = $(this).attr("data-evdate");
    var image = $(this).attr("data-image");
    var evContent = $(this).attr("data-evcontent");

    $(".event-info-container .header span").html(title);
    $(".event-info-container .event-info-date").html(evDate);

    if (image == "") {
      $(".event-info-container div.ui.fluid.image").hide();
    } else {
      $(".event-info-container div.ui.fluid.image").show();
      $(".event-info-container .image.content img").attr("src", image);
    }

    $(".event-info-container .content p").html(evContent);

    $(".event-info-container")
      .modal({
        blurring: false,
        inverted: true,
        detachable: true,
      })
      .modal("show");
  });
  $(".close-info").click(function () {
    $(".event-info-container").modal("hide");
  });

  // Merchandise item / modal price styling

  $(".variations, .merch-item-modal.has_variations .description").each(
    function () {
      var p = $(this).parent().find('span[itemprop="price"]').attr("content");

      // Has variations
      if (
        $(this).find("select option").not(":disabled").length &&
        $(this).find('[data-single-item="true"]').length == 0
      ) {
        var price = $(this).find("select option:selected").data("price");
        if (price.length) {
          price.trim();
          price.replace("£", "");
        }
        $(this)
          .parent()
          .find('span[itemprop="price"]')
          .text(price)
          .attr("content", price);
      }

      // Single item (1 variation)
      else if ($(this).find('[data-single-item="true"]').length) {
        if (
          $(this).parent().find('span[itemprop="price"]').attr("content") ==
          "SOLD OUT"
        ) {
          $(this)
            .parent()
            .find('button[type="submit"]')
            .prop("disabled", true)
            .css("opacity", "0.8");
        }
        $(this).parent().find('span[itemprop="price"]').text(p);
      }

      // Sold out
      else {
        $(this).find("select option").eq(0).prop("selected", true);
        $(this).parent().find('span[itemprop="priceCurrency"]').hide();
        $(this)
          .parent()
          .find('span[itemprop="price"]')
          .text("SOLD OUT")
          .attr("content", price);
      }
    }
  );

  var prev;
  var default_img;

  $(".merch-item.has_variations, .merch-item-modal.has_variations").each(
    function () {
      $(this)
        .find("select")
        .on("change", function () {
          if ($(this).find("option:selected").data("image")) {
            // Get variation override image from data-price attr
            var img = $(this).find("option:selected").data("image_path");

            // Get current image URL
            var curr_img = $(this)
              .find(".item-image, .modal-image")
              .attr("src");

            // Get path before the last '/'
            //var curr_path = curr_img.slice(0, curr_img.lastIndexOf('/'));
            var curr_path = $(this).find("option:selected").data("image_path");

            // Swap last part of image url with new image filename
            $(this)
              .parent()
              .find(".item-image, .modal-image")
              .attr("src", curr_path + img);
          }
        });
    }
  );

  // Get current price of variation
  $(".merch-select, .modal-merch-select")
    .on("focus", function () {
      if ($(this).find("option").not(":disabled").length) {
        var val = $(this)
          .find("option:selected")
          .data("price")
          .toString()
          .replace("£", "")
          .trim();
        $(this).attr("data-val", val);

        prev = val;
      }

      // When a different variation is selelected, update price and image (if variation has image override). If different to previous variation, apply temp text effect to price.
    })
    .on("change", function () {
      var value = $(this)
        .find("option:selected")
        .data("price")
        .toString()
        .replace("£", "")
        .trim();
      $(this)
        .parent()
        .find('span[itemprop="price"]')
        .text(value)
        .attr("content", value);

      var current = $(this)
        .find("option:selected")
        .data("price")
        .toString()
        .trim();

      if (current != prev) {
        if ($(".border-wrapped-header").css("background") != "255,255,255") {
          $(this).parent().find(".variation-price span").addClass("updated");

          setTimeout(function () {
            $("span.updated").removeClass("updated");
          }, 1000);
        }
      }

      // If merch item has variations
      if (
        $(this)
          .closest(".merch-item, .merch-item-modal")
          .hasClass("has_variations")
      ) {
        $(this)
          .find("option:selected")
          .each(function () {
            // var default_img = $(this).closest('.merch-item, .merch-item-modal').find('img').data('path');
            var default_img = $(this).data("default_image");

            // If variation has image override swap the filename (last part of img url) with correlating variation image
            if ($(this).data("image")) {
              // Get variation override image from data-price attr
              var img = $(this).data("image");

              // Get current image URL
              var curr_img = $(this)
                .closest(".merch-item, .merch-item-modal")
                .find(".item-image, .modal-image")
                .attr("src");

              // Get path before the last '/'
              //var curr_path = curr_img.slice(0, curr_img.lastIndexOf('/'));
              var curr_path = $(this).data("image_path");

              // Swap last part of image url with new image filename
              $(this)
                .closest(".merch-item, .merch-item-modal")
                .find(".item-image, .modal-image")
                .attr("src", curr_path + img);
            }
            // Otherwise just swap it back to the default image
            else {
              $(this)
                .closest(".merch-item, .merch-item-modal")
                .find(".item-image, .modal-image")
                .attr("src", default_img);
            }
          });
      }

      // In order to get previous price value, trigger blur on change
      $(this).trigger("blur");
    });

  // Lookup field appearance

  $('[data-fieldtype="lookup"] input').on("keyup", function () {
    if ($(this).val() == 0) {
      $(".rnJq-search-results").hide();
    }
  });

  $(".rnJq-search-results").click(function () {
    $(this).hide();
  });

  function update_dob_fields() {
    if ($("input.dob-input").length) {
      if ($("input.dob-input").val() != "") {
        var date = $("input.dob-input").first().val().split("-");

        //regex needed as it sometimes returns with the st/nd/rd/th
        var dayRegEx = /([0-9]{1,2})/;
        var matches = dayRegEx.exec(date[0]);
        var day = matches[0];
        var month = date[1];
        var year = date[2];

        $(".dob-day option")
          .filter(function () {
            return $(this).val() == day;
          })
          .prop("selected", true);

        $(".dob-month option")
          .filter(function () {
            return $(this).val() == month;
          })
          .prop("selected", true);

        $(".dob-year option")
          .filter(function () {
            return $(this).val() == year;
          })
          .prop("selected", true);
      }
    }
  }

  // Date of birth picker with select fields

  if ($(".dob-fields").length == 1) {
    $(".dob-fields select").each(function () {
      $(this).on("change", function () {
        var day = $(".dob-day").val();
        var month = $(".dob-month").val();
        var year = $(".dob-year").val();

        /*function nth(day) {
                    if(day>3 && day<21) return 'th';
                      switch (day % 10) {
                          case 1:  return "st";
                          case 2:  return "nd";
                          case 3:  return "rd";
                          default: return "th";
                    }
                } */

        var dob = "" + year + "-" + month + "-" + day + "";

        if ($(this).closest(".dob-fields").find("input.dob-input").length) {
          $(this)
            .closest(".dob-fields")
            .find("input.dob-input")
            .val(dob)
            .trigger("input");
        } else {
          $(this)
            .closest(".dob-fields")
            .parent()
            .find("input.dob-input")
            .val(dob)
            .trigger("input");
        }
      });
    });

    $(document).on("input", "input.dob-input", function (e) {
      console.log($(e.currentTarget).val());
    });

    // Populate select date picker dropdowns with birthday data if present

    update_dob_fields();
  }

  // Assign an ID if multiple DOB fields on form to stop values going to wrong DOB inputs
  else if ($(".dob-fields").length > 1) {
    var i = 1;

    $(".dob-fields").each(function (i) {
      i = i++;

      $(this).attr("id", "dob" + i);
    });

    $(".dob-fields").each(function (i) {
      $(this)
        .find("select")
        .on("change", function () {
          var day = $("#dob" + i)
            .find(".dob-day")
            .val();
          var month = $("#dob" + i)
            .find(".dob-month")
            .val();
          var year = $("#dob" + i)
            .find(".dob-year")
            .val();

          var dob = "" + year + "-" + month + "-" + day + "";

          $("#dob" + i)
            .find("input.dob-input")
            .val(dob);
        });

      $(this)
        .find("input.dob-input")
        .each(function () {
          console.log($(this).parent().attr("id") + ": " + $(this).val());

          // Populate select date picker dropdowns with birthday data if present

          if ($(this).val() != "") {
            var date = $(this).val().split("-");

            //regex needed as it sometimes returns with the st/nd/rd/th
            var dayRegEx = /([0-9]{1,2})/;
            var matches = dayRegEx.exec(date[0]);
            var day = matches[2];
            var month = date[1];
            var year = date[0];

            $("#dob" + i)
              .find(".dob-day option")
              .filter(function () {
                return $(this).val() == day;
              })
              .prop("selected", true);

            $("#dob" + i)
              .find(".dob-month option")
              .filter(function () {
                return $(this).val() == month;
              })
              .prop("selected", true);

            $("#dob" + i)
              .find(".dob-year option")
              .filter(function () {
                return $(this).val() == year;
              })
              .prop("selected", true);
          }
        });
    });
  }

  $(".rnJq-search-results").click(function () {
    $(this).hide();
  });

  function update_dob_fields() {
    if ($("input.dob-input").length) {
      if ($("input.dob-input").val() != "") {
        var date = $("input.dob-input").first().val().split("-");

        //regex needed as it sometimes returns with the st/nd/rd/th
        var dayRegEx = /([0-9]{1,2})/;
        var matches = dayRegEx.exec(date[0]);
        var day = matches[0];
        var month = date[1];
        var year = date[2];

        $(".dob-day option")
          .filter(function () {
            return $(this).val() == day;
          })
          .prop("selected", true);

        $(".dob-month option")
          .filter(function () {
            return $(this).val() == month;
          })
          .prop("selected", true);

        $(".dob-year option")
          .filter(function () {
            return $(this).val() == year;
          })
          .prop("selected", true);
      }
    }
  }

  // Date of birth picker with select fields

  if ($(".dob-fields").length == 1) {
    $(".dob-fields select").each(function () {
      $(this).on("change", function () {
        var day = $(".dob-day").val();
        var month = $(".dob-month").val();
        var year = $(".dob-year").val();

        /*function nth(day) {
					if(day>3 && day<21) return 'th';
					  switch (day % 10) {
						  case 1:  return "st";
						  case 2:  return "nd";
						  case 3:  return "rd";
						  default: return "th";
					}
				} */

        var dob = "" + year + "-" + month + "-" + day + "";

        if ($(this).closest(".dob-fields").find("input.dob-input").length) {
          $(this)
            .closest(".dob-fields")
            .find("input.dob-input")
            .val(dob)
            .trigger("input");
        } else {
          $(this)
            .closest(".dob-fields")
            .parent()
            .find("input.dob-input")
            .val(dob)
            .trigger("input");
        }
      });
    });

    $(document).on("input", "input.dob-input", function (e) {
      console.log($(e.currentTarget).val());
    });

    // Populate select date picker dropdowns with birthday data if present

    update_dob_fields();
  }

  // Assign an ID if multiple DOB fields on form to stop values going to wrong DOB inputs
  else if ($(".dob-fields").length > 1) {
    var i = 1;

    $(".dob-fields").each(function (i) {
      i = i++;

      $(this).attr("id", "dob" + i);
    });

    $(".dob-fields").each(function (i) {
      $(this)
        .find("select")
        .on("change", function () {
          var day = $("#dob" + i)
            .find(".dob-day")
            .val();
          var month = $("#dob" + i)
            .find(".dob-month")
            .val();
          var year = $("#dob" + i)
            .find(".dob-year")
            .val();

          var dob = "" + year + "-" + month + "-" + day + "";

          $("#dob" + i)
            .find("input.dob-input")
            .val(dob);
        });

      $(this)
        .find("input.dob-input")
        .each(function () {
          console.log($(this).parent().attr("id") + ": " + $(this).val());

          // Populate select date picker dropdowns with birthday data if present

          if ($(this).val() != "") {
            var date = $(this).val().split("-");

            //regex needed as it sometimes returns with the st/nd/rd/th
            var dayRegEx = /([0-9]{1,2})/;
            var matches = dayRegEx.exec(date[0]);
            var day = matches[2];
            var month = date[1];
            var year = date[0];

            $("#dob" + i)
              .find(".dob-day option")
              .filter(function () {
                return $(this).val() == day;
              })
              .prop("selected", true);

            $("#dob" + i)
              .find(".dob-month option")
              .filter(function () {
                return $(this).val() == month;
              })
              .prop("selected", true);

            $("#dob" + i)
              .find(".dob-year option")
              .filter(function () {
                return $(this).val() == year;
              })
              .prop("selected", true);
          }
        });
    });
  }

  if ($(".dob-fields").length) {
    // Date of birth select boxes

    $.dobPicker({
      // Selectopr Classes
      daySelector: ".dob-day",
      monthSelector: ".dob-month",
      yearSelector: ".dob-year",

      // Default option values
      dayDefault: "DD",
      monthDefault: "MM",
      yearDefault: "YYYY",
      dateFormat: "yy-mm-dd",

      // Minimum age
      minimumAge: 1,

      // Maximum age
      maximumAge: 130,
    });
  }

  // Baskets - handling longer items to fit on one line

  $(".step-left.sub-item").each(function () {
    if ($(this)[0].scrollWidth > $(this).innerWidth()) {
      $(
        '<span class="desc-btn basket-popup" data-variation="inverted" data-offset="-2" data-content="' +
          $(this).data("content") +
          '" title="Click to view full description">' +
          '<i class="info circle icon"></i>' +
          "</span>"
      ).insertAfter($(this));
    }

    $(window).resize(function () {});
  });

  $(window).resize(function () {
    $(".step-left.sub-item").each(function () {
      if ($(this)[0].scrollWidth > $(this).innerWidth()) {
        if ($(this).parent().find(".basket-popup").length == 0) {
          $(
            '<span class="desc-btn basket-popup" data-variation="inverted" data-offset="-2" data-content="' +
              $(this).data("content") +
              '" title="Click to view full description">' +
              '<i class="info circle icon"></i>' +
              "</span>"
          ).insertAfter($(this));
        }
      } else {
        $(this).parent().find(".basket-popup").remove();
      }
    });

    $(".basket-popup").popup({
      on: "click",
      inverted: true,
      position: "top center",
      distanceAway: -5,
      onShow: function (e) {
        $(e).parent().css("background", "#fffdd5");
      },
      onHide: function (e) {
        $(e).parent().css("background", "initial");
      },
    });
  });

  $(".basket-popup").popup({
    on: "click",
    inverted: true,
    position: "top center",
    distanceAway: -5,
    onShow: function (e) {
      $(e).parent().css("background", "#fffdd5");
    },
    onHide: function (e) {
      $(e).parent().css("background", "initial");
    },
  });
});

// Move lookup small.error text into field placeholder

$("small.error").each(function () {
  var $text = $(this).text();
  if ($text == "Start typing and select an option") {
    $(this).parent().find("input").attr("placeholder", $text);
    $(this).hide();
  }
});

// App Maps responsive previews

function responsivePreview() {
  var preview_map_img = new Image();
  var regexp = /url\((.+)\)/i,
    regexp2 = /["']/gi,
    res = regexp.exec(
      $(".image-map-preview, .geo-map-preview").css("background-image")
    ),
    img_src = res[1].replace(regexp2, "");
  preview_map_img.src = img_src;
  var prev_img_width = preview_map_img.width;
  var prev_img_height = preview_map_img.height;

  console.log(prev_img_height);

  $(".inner-wrapper").css("max-height", prev_img_height);
}

// Result Leaderboards generic JS

function responsiveTabs() {
  if ($(".leaderboard-tabs").length) {
    var tabs_width = 0;
    $(".leaderboard-tabs .item").each(function () {
      tabs_width += $(this).outerWidth();
    });

    if ($(window).width() < tabs_width) {
      console.log(tabs_width);
      if ($(".leaderboard-tabs").hasClass("tabular")) {
        $(".leaderboard-tabs")
          .removeClass("tabular")
          .addClass("vertical fluid stackable");
      }
    }
  }
}

$(function () {
  responsiveTabs();

  $(window).resize(function () {
    responsiveTabs();
  });
});

/*******************************************************************************
 JS Function to avoid double click on anchor tags
 add the following class one-click-submit to any <a> tag
 *******************************************************************************/
$(function () {
  $(".one-click-submit").one("click", function (e) {
    $(this).attr("style", "pointer-events: none;");
  });
});

/*******************************************************************************
 End JS Function to avoid double click on anchor tags
 *******************************************************************************/

/*************************************************************************************

 Move NumericWithDecimal Function here (to stop being overwritten)

 **************************************************************************************/

function numericWithDecimal(inputValue, decimalLength) {
  // Fix bug in Firefox, convert input to type=text if user agent is FF and input value contains a decimal place
  if (
    $('input[type="number"]:focus').length &&
    navigator.userAgent.toLowerCase().indexOf("firefox") > -1
  ) {
    var numberInputs = $('input[type="number"]:focus');
    if (numberInputs.val().indexOf(".")) {
      numberInputs.attr("type", "text");
      numberInputs[0].focus();
      numberInputs[0].setSelectionRange(
        numberInputs[0].value.length,
        numberInputs[0].value.length,
        "forward"
      );
      //numberInputs[0].select()
    }
  }
  // Set default value for decimalLength - old way as we are not using ES2015.
  decimalLength = typeof decimalLength !== "undefined" ? decimalLength : 2;
  // Create a regular expression where we can pass in a variable.
  var expression = new RegExp("[^\\d.]", "g");
  var match_expression = new RegExp(
    "(\\d{0,8})[^.]*((?:\\.\\d{0," + decimalLength + "})?)",
    "g"
  );
  match = match_expression.exec(inputValue.replace(expression, ""));
  return match[1] + match[2];
}

function moneyValueWithNeg(inputValue) {
  // Fix bug in Firefox, convert input to type=text if user agent is FF and input value contains a decimal place
  if (
    $('input[type="number"]:focus').length &&
    navigator.userAgent.toLowerCase().indexOf("firefox") > -1
  ) {
    var numberInputs = $('input[type="number"]:focus');
    if (numberInputs.val().indexOf(".")) {
      numberInputs.attr("type", "text");
      numberInputs[0].focus();
      numberInputs[0].setSelectionRange(
        numberInputs[0].value.length,
        numberInputs[0].value.length,
        "forward"
      );
      //numberInputs[0].select()
    }
  }
  // Create a regular expression where we can pass in a variable.
  var expression = new RegExp("[^-?\\^\\d.]", "g");
  var match_expression = new RegExp("(-?\\d{0,8})[^.]*((?:\\.\\d{0,2})?)", "g");
  match = match_expression.exec(inputValue.replace(expression, ""));
  return match[1] + match[2];
}

/*************************************************************************************

 Innit Semantic UI JS elements after Ajax call

 *************************************************************************************/

var ajax_running = false;
$(document).ajaxStart(function () {
  ajax_running = true;
});

$(function () {
  if (ajax_running == true) {
    $(document).ajaxSuccess(function (event, request, settings) {
      $(".ui.checkbox:not(.ui.toggle.checkbox)").checkbox();
      $(".ui.acordion").accordion();
      $(".info-popup").popup();
      $(
        ".ui.dropdown:not(.ui.dropdown.lang-selector):not(.ui.dropdown.allow-blank):not(.ui.dropdown.no-default)"
      ).dropdown();
      $(".ui.dropdown.allow-blank").dropdown({
        placeholder: false,
      });

      // Help and Tips trigger

      $(".helper-action").on("click", function () {
        var helpTitle = $(this).parent().text();
        var helpText = $(this).attr("data-helper-text");

        if ($(".ui.sidebar.tips .list .item").length == 0) {
          $(
            '<div class="item"><div class="header">' +
              helpTitle +
              '</div><div class="half-spacer"></div>' +
              helpText +
              "</div>"
          ).prependTo(".ui.sidebar.tips .list");

          $(".ui.sidebar.tips")
            .sidebar("setting", "transition", "overlay")
            .sidebar("setting", "dimPage", "false")
            .sidebar("toggle");
        }
      });

      // Clear the sidebar of its content when closed

      $(".ui.sidebar.tips").sidebar({
        onHidden: function () {
          $(".ui.sidebar.tips .list").html("");
        },
      });

      $("#close-tips").click(function () {
        $(".ui.sidebar.tips").sidebar("hide");
      });
    });
  }
});

/*******************************************************************************
 A variety of JS functions added by mattzb, March 2021, for bug fixes.
 The templates that use these classes are specified in the comments
 Accompanies public/css/mattzb.css
 *******************************************************************************/

/*
custom confirm modal, to help with SAAS-3310
allows "ok" button to be customised by specifying "data-oktext" in the html
adapted from $(document).on('click', '.confirm-alert' ... func in all.js
used in resources/views/organiser/emails/view.php

modal template used can be found in resources/views/common/layout.blade.php
<div class="ui small modal confirm" id="confirm-modal">
  <div class="header">Please confirm</div>
  <div class="content">
	  <p></p>
	</div>
	<div class="actions">
	  <div class="ui cancel negative button">Cancel</div>
	  <div class="ui ok positive button">OK</div>
	</div>
</div>


example usage :  (this was the fix for SAAS-970)

<a href="{{ action('Auth\AuthController@getLogout') }}"
class="white link item custom-confirm-alert"
data-question="Are you sure?"
data-oktext="Logout"
>Logout</a>


*/
$(".custom-confirm-alert").on("click", function (ev) {
  ev.preventDefault();
  var $btn = $(this);
  if ($(this).data("question")) {
    $("#confirm-modal .content > p").html($(this).data("question"));
    // } else { alert("no question text");
  }
  if ($(this).data("oktext")) {
    $("#confirm-modal .ok").html($(this).data("oktext"));
    // } else { alert("no ok text");
  }
  $("#confirm-modal")
    .modal({
      blurring: false,
      inverted: true,
      closable: false,
      onDeny: function onDeny() {
        return true;
      },
      onApprove: function onApprove() {
        if ($btn.attr("href")) {
          window.location.href = $btn.attr("href");
        } else {
          $btn.closest("form").submit();
        }
      },
    })
    .modal("show");
});

/*
footer positioning fix - SAAS-3607
applies to all pages
resources/views/common/public/layout.blade.php
*/

function windowFooterPositioning() {
  var pageElement = document.getElementById("pageContentPusher");
  var footerElement = document.getElementById("footerBar");
  if (pageElement && footerElement) {
    if (pageElement.getBoundingClientRect().bottom + 20 < window.innerHeight) {
      footerElement.classList.add("footer_sticky");
    } else {
      footerElement.classList.remove("footer_sticky");
    }
  }
}

window.onload = function () {
  windowFooterPositioning();
};
window.addEventListener("resize", windowFooterPositioning, false);

/*
 * Shuffle jQuery array of elements - see Fisher-Yates algorithm
 */
jQuery.fn.shuffle = function () {
  var j;
  for (var i = 0; i < this.length; i++) {
    j = Math.floor(Math.random() * this.length);
    $(this[i]).before($(this[j]));
  }
  return this;
};
